import {
  OurInsightsRiskSnapshotsContext,
  OurInsightsRiskSnapshotsProps,
} from '../..';
import { BCMSParserFunction } from '../parser';

export const OurInsightsRiskSnapshotsParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const props: OurInsightsRiskSnapshotsProps = {
      posts: cache.risk_snapshots.map((snapshot) => {
        return {
          type: 'risk_snapshot',
          title: snapshot.meta.title,
          description: snapshot.meta.description,
          cover_image: snapshot.meta.cover_image,
          cover_image_alt_text: snapshot.meta.cover_image_alt_text,
          slug: snapshot.meta.slug,
        };
      }),
    };
    const context: OurInsightsRiskSnapshotsContext = {
      posts: JSON.stringify(props.posts),
    };
    createPage({
      path: '/our-insights/risk-snapshots',
      component,
      context,
    });
  },
);
