import { OverviewViewContext, OverviewViewProps } from '../../../types';
import { BCMSParserFunction } from '../parser';

export const OverviewParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.overview.forEach((overview) => {
      const props: OverviewViewProps = {
        page: {
          title: overview.meta.title,
          subtitle: overview.meta.subtitle,
          cover_image: overview.meta.cover_image,
          description: overview.meta.description,
          aside: overview.meta.aside,
        },
        content: overview.content,
      };
      const context: OverviewViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        path: `/services/${overview.meta.slug}`,
        component,
        context,
      });
    });
  },
);
