import { EntryContentParsed, ReferenceWidgetType } from '../../types';

export interface ContentHandlerPrototype {
  format(content: EntryContentParsed): EntryContentParsed;
}

function contentHandler() {
  const self: ContentHandlerPrototype = {
    format(content) {
      return content.map((prop) => {
        if (prop.type.startsWith('HEADING_')) {
          prop.value = (prop.value as string)
            .replace(/<p>/g, '')
            .replace(/<\/p>/g, '')
            .replace(/<br>/g, '')
            .replace(/<\/br>/g, '');
        }
        if (prop.type === 'WIDGET' && prop.name === 'reference') {
          const value = (prop.value as unknown) as ReferenceWidgetType;

          prop.value = {
            reference: {
              overview: value.reference.overview.map((e: any) => {
                return {
                  title: e.meta.en.title,
                  slug: `/services/${e.meta.en.slug}`,
                  description: e.meta.en.description,
                  cover_image: e.meta.en.cover_image,
                };
              }),
              services: value.reference.services.map((e: any) => {
                const formattedGroup = (e.meta.en.page_group.selected as string)
                  .toLowerCase()
                  .replace(/ /g, '-');

                return {
                  title: e.meta.en.title,
                  slug: `/${formattedGroup}/${e.meta.en.slug}`,
                  description: e.meta.en.description,
                  cover_image: e.meta.en.cover_image,
                };
              }),
            },
          } as any;
        }

        return prop;
      });
    },
  };

  return self;
}

export const ContentHandler = contentHandler();
