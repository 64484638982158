import React from 'react';
import {
  CrimeIndexHero,
  Layout,
  CrimeIndexRelatedByTag,
  Article,
  AsideHubspotForm,
} from '../components';
import { CrimeIndexViewProps } from '../types';

const CrimeIndexView: React.FunctionComponent<CrimeIndexViewProps> = ({
  page,
  content,
}) => {
  return (
    <Layout
      description={page.hero.description}
      title={page.title}
      OGImageUri={page.hero.image.uri}
      className={`crimeIndexPage ${page.dark ? 'darkPage' : ''} ${
        page.hero.full_width_image ? 'fullWidthBanner' : ''
      } ${page.slug.toLowerCase().replace(/ /g, '-')} ${
        page.two_column_layout ? 'articleWithAsideLayout' : ''
      }`}
      noPinkertonInTitle={true}
    >
      <CrimeIndexHero
        hero={page.hero}
        dark={page.dark}
        twoCol={page.two_column_layout}
      />
      {page.two_column_layout ? (
        <div className="overview product">
          <div className="wrapper">
            <div className="twoColSingle">
              <Article content={content} />
              {page.hubspot?.form_id && (
                <AsideHubspotForm
                  title={page.hubspot.title}
                  form_id={page.hubspot.form_id}
                  dark={page.hubspot.dark}
                  className={`twoColSingle--aside ${
                    page.hubspot.className || ''
                  }`}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Article content={content} fullWidth={true} />
      )}
      {page.related.items.length > 0 && (
        <CrimeIndexRelatedByTag
          title={page.related.title}
          items={page.related.items}
        />
      )}
    </Layout>
  );
};

export default CrimeIndexView;
