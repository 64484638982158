import React from 'react';
import {
  Breadcrumb,
  HubspotForm,
  Image,
  LanguageSwitch,
  Layout,
} from '../../components';
import { FindARiskAdvisorDirectorViewProps } from '../../types';

const FindARiskAdvisorDirectorView: React.FunctionComponent<
  FindARiskAdvisorDirectorViewProps
> = ({ director, region, content, directorsWithRegions, languageSwitch }) => {
  const [showForm, setShowForm] = React.useState(false);
  const [allRegs, setAllRegs] = React.useState([]);

  React.useEffect(() => {
    setAllRegs(
      directorsWithRegions
        .filter((e) => e.director._id === director?.id)
        .map((e) => e.regions)
        .reduce((acc, e) => {
          e.forEach((i: any) => {
            acc.push(i);
          });
          return acc;
        }, []),
    );
  }, []);

  return (
    <Layout
      title={director.page_title || `${director.title} | ${region.title}`}
      description=""
    >
      <div className="overview risk-advisor risk-advisor_single">
        <div className="wrapper">
          <div className="risk-advisor_single--top">
            <Breadcrumb
              items={[
                {
                  label: 'Find your Pinkerton',
                  path: '/find-your-pinkerton',
                },
                {
                  label: director.title,
                  path: `/find-your-pinkerton/${director.slug}`,
                },
              ]}
            />
            {languageSwitch.items.length > 1 && (
              <LanguageSwitch items={languageSwitch.items} />
            )}
          </div>
          <div className="twoColSingle">
            <aside className="twoColSingle--aside">
              <div className="director director_left">
                <div className="director--image">
                  <div className="director--image-wrapper">
                    <Image
                      src={director.image_uri}
                      alt={
                        director.image_alt_text
                          ? director.image_alt_text
                          : director.title
                      }
                    />
                  </div>
                </div>
                <div className="director--details">
                  <h1 className="director--name">{director.title}</h1>
                  <div className="director--description">
                    {director.description}
                  </div>
                  <div className="director--regions">
                    <h2 className="director--regions-title">
                      {director.location_title || region.title}
                    </h2>
                    <h3 className="director--regions-countries">
                      {allRegs.map((subRegion: any, subRegionIndex) => {
                        return (
                          <span key={subRegionIndex}>
                            {subRegionIndex === allRegs.length - 1
                              ? subRegion.meta.title.replace(/-/g, '')
                              : `${subRegion.meta.title.replace(/-/g, '')}, `}
                          </span>
                        );
                      })}
                    </h3>
                  </div>
                  <button
                    className="btn btn_block director--contact"
                    onClick={() => {
                      setShowForm(!showForm);
                    }}
                  >
                    <span>Contact {director.title.split(' ')[0]}</span>
                  </button>
                </div>
              </div>
            </aside>
            <section className="twoColSingle--main">
              <div className="director director_right">
                {content.map((data, dataIndex) => {
                  switch (data.type) {
                    case 'HEADING_1':
                    case 'HEADING_2':
                    case 'HEADING_3':
                    case 'PARAGRAPH': {
                      return (
                        <div
                          className="director--content-group"
                          key={dataIndex}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.value as string,
                            }}
                          ></div>
                        </div>
                      );
                    }
                    case 'LIST': {
                      return (
                        <div
                          className="director--content-group"
                          key={dataIndex}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.value as string,
                            }}
                          ></div>
                        </div>
                      );
                    }
                    default: {
                      return <div key={dataIndex}></div>;
                    }
                  }
                })}
              </div>
            </section>
          </div>
        </div>
      </div>
      <div
        className="risk-advisor--form"
        style={{
          opacity: showForm ? '1' : '0',
          pointerEvents: showForm ? 'all' : 'none',
        }}
      >
        <div className="risk-advisor--form-main">
          <HubspotForm
            formId={director.form_id || '2191945a-e878-42cc-8b9b-78341b158728'}
          />
          <button
            className="risk-advisor--form-close"
            onClick={() => {
              setShowForm(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeOpacity={0.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
        <div
          className="risk-advisor--form-overlay"
          onClick={() => {
            setShowForm(false);
          }}
        />
      </div>
    </Layout>
  );
};

export default FindARiskAdvisorDirectorView;
