import {
  MediaOverviewViewContext,
  MediaOverviewViewProps,
} from '../../../types';
import { BCMSParserFunction } from '../parser';

export const MediaOverviewParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const props: MediaOverviewViewProps = {
      posts: cache.media.map((media) => {
        return {
          title: media.meta.title,
          slug: media.meta.slug,
        };
      }),
    };
    const context: MediaOverviewViewContext = {
      posts: JSON.stringify(props.posts),
    };
    createPage({
      path: '/media',
      component,
      context,
    });
  },
);
