import * as path from 'path';
import {
  PressRoomOverviewContext,
  PressRoomOverviewProps,
  PressRoomPostContext,
  PressRoomPostProps,
} from '../../types';
import { GeneralUtil } from '../util';
import { BCMSParserFunction } from './parser';

export const PressRoomParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const singlePageTemplate = path.join(
      process.cwd(),
      'src',
      'templates',
      'press-room',
      'post.tsx',
    );
    const props: PressRoomOverviewProps = {
      inTheNews: [],
      recentEntries: [],
      pressArchive: [],
    };
    cache.in_the_news.forEach((news) => {
      props.inTheNews.push({
        title: news.meta.title,
        slug: `in-the-news/${news.meta.slug}`,
        description: news.meta.description,
        cover_image: news.meta.cover_image,
        cover_image_alt_text: news.meta.cover_image_alt_text,
      });
    });
    cache.press.forEach((press) => {
      const singlePageProps: PressRoomPostProps = {
        press,
        relatedPress: GeneralUtil.getRelated(cache.press, press, 3).map((e) => {
          return {
            title: e.title,
            uri: `/press-room/${e.slug}`,
          };
        }),
      };
      const singlePageContext: PressRoomPostContext = {
        press: JSON.stringify(singlePageProps.press),
        relatedPress: JSON.stringify(singlePageProps.relatedPress),
      };
      createPage({
        component: singlePageTemplate,
        path: `/press-room/${press.meta.slug}`,
        context: singlePageContext,
      });
      props.recentEntries.push({
        title: press.meta.title,
        slug: press.meta.slug,
        description: press.meta.description,
        cover_image: press.meta.cover_image,
        cover_image_alt_text: press.meta.cover_image_alt_text,
      });
      const pressYear = new Date(press.meta.date).getFullYear();
      let pressArchiveWithSameYearIndex = -1;
      for (let i = 0; i < props.pressArchive.length; i = i + 1) {
        if (props.pressArchive[i].year === pressYear) {
          pressArchiveWithSameYearIndex = i;
          break;
        }
      }
      if (pressArchiveWithSameYearIndex !== -1) {
        props.pressArchive[pressArchiveWithSameYearIndex].press.push({
          date: press.meta.date,
          slug: press.meta.slug,
          title: press.meta.title,
        });
      } else {
        props.pressArchive.push({
          year: pressYear,
          press: [
            {
              date: press.meta.date,
              slug: press.meta.slug,
              title: press.meta.title,
            },
          ],
        });
      }
    });
    props.pressArchive.sort((a, b) => b.year - a.year);
    const context: PressRoomOverviewContext = {
      inTheNews: JSON.stringify(props.inTheNews),
      recentEntries: JSON.stringify(props.recentEntries.slice(0, 3)),
      pressArchive: JSON.stringify(props.pressArchive),
    };
    createPage({
      component,
      path: '/press-room',
      context,
    });
  },
);
