import { BCMSParserFunction } from './parser';

export const EmploymentScreeningParser = BCMSParserFunction(
  async (createPage, component) => {
    createPage({
      path: '/employment-screening',
      component,
    });
  },
);
