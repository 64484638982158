import { ConsultantProgramViewContext, ConsultantProgramViewProps } from '..';
import { BCMSParserFunction } from './parser';

export const ConsultantProgramParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.consultant_program.forEach((entry) => {
      const props: ConsultantProgramViewProps = {
        page: {
          title: entry.meta.title,
          subtitle: entry.meta.subtitle,
          slug: entry.meta.slug,
          mobile_cta: entry.meta.mobile_cta,
          cover_image: entry.meta.cover_image,
          aside_footer: entry.meta.aside_footer,
          aside: entry.meta.aside,
        },
        content: entry.content,
      };
      const context: ConsultantProgramViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        path: entry.meta.slug,
        component,
        context,
      });
    });
  },
);
