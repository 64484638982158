import {
  FindARiskAdvisorDirectorViewProps,
  FindARiskAdvisorDirectorViewContext,
  FindARiskAdvisorDirectorViewPropsRegion,
  FindARiskAdvisorDirectorViewPropsRegionSubRegion,
} from '../..';
import { EntryContentParsedItem, PropType } from '../../../types';
import { BCMSParserFunction } from '../parser';
import { ContentHandler } from '../../entries/_content-handler';
import { ISOLangs } from '../../../data';

export const FindARiskAdvisorDirectorParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.directors.forEach((director) => {
      const langs = Object.keys(director.meta)
        .map((e) => e)
        .filter((e) => director.meta[e].title.trim());
      const languageSwitch: Array<{ label: string; path: string }> = langs.map(
        (lang) => {
          return {
            label: ISOLangs[lang || 'en'].nativeName,
            path: `/find-your-pinkerton/${director.meta[lang].slug}${
              lang !== 'en' ? '/' + lang : ''
            }`,
          };
        },
      );
      const getRegionByDirectorTitle = (
        directorTitle: string,
      ): FindARiskAdvisorDirectorViewPropsRegion => {
        let regionTitle = '';
        let subRegions: FindARiskAdvisorDirectorViewPropsRegionSubRegion[] = [];
        for (const i in cache.regions) {
          const region = cache.regions[i];
          for (const j in region.meta.directors) {
            const dir = region.meta.directors[j];
            if (
              dir.director &&
              dir.director.meta &&
              dir.director.meta.en.title === directorTitle
            ) {
              regionTitle = region.meta.title;
              subRegions = dir.sub_regions
                .map((subRegion) => {
                  return {
                    title: subRegion.meta.title,
                  };
                })
                .filter((e) => {
                  return ![
                    'Clint Richards',
                    'David Valenzuela',
                    'AMEA',
                    'Europe',
                    'Americas',
                  ].includes(e.title);
                });
              break;
            }
          }
        }
        return {
          title: regionTitle.replace(/\n/g, ''),
          sub_regions: subRegions.map((e) => {
            return {
              ...e,
              title: e.title.replace(/\n/g, ''),
            };
          }),
        };
      };
      langs.forEach((lang) => {
        const props: FindARiskAdvisorDirectorViewProps = {
          director: {
            id: director._id,
            title: director.meta[lang].title,
            page_title: director.meta[lang].page_title,
            subtitle: director.meta[lang].subtitle,
            slug: director.meta[lang].slug,
            description: director.meta[lang].description,
            form_id: director.meta[lang].form_id,
            image_uri: director.meta[lang].image_uri,
            image_alt_text: director.meta[lang].image_alt_text,
            location_title: director.meta[lang].location_title,
            lat: director.meta[lang].lat,
            lng: director.meta[lang].lng,
          },
          region: getRegionByDirectorTitle(director.meta[lang].title),
          content: ContentHandler.format(director.content[lang]),
          directorsWithRegions: cache.regions
            .filter((region) => {
              const directorMeta = region.meta.directors[0]?.director.meta;
              return directorMeta;
            })
            .map((region) => {
              const output = JSON.parse(
                JSON.stringify(region.meta.directors[0]),
              );
              output.director.meta = output.director.meta.en;
              output.director.content = output.director.content.en.find(
                (e: EntryContentParsedItem) => e.type === PropType.PARAGRAPH,
              )?.value;
              output.regions = [...output.sub_regions].map((subRegion) => {
                subRegion.content = [];
                subRegion.meta.title = subRegion.meta.title.replace(/\n/g, '');
                return subRegion;
              });
              output.sub_regions = undefined;
              return output;
            }),
          languageSwitch: {
            items: languageSwitch,
          },
        };
        const context: FindARiskAdvisorDirectorViewContext = {
          director: JSON.stringify(props.director),
          region: JSON.stringify(props.region),
          content: JSON.stringify(props.content),
          directorsWithRegions: JSON.stringify(props.directorsWithRegions),
          languageSwitch: JSON.stringify(props.languageSwitch),
        };
        const isEn = lang === 'en';
        if (director.meta[lang].draft === false) {
          createPage({
            path: `/find-your-pinkerton/${director.meta[lang].slug}${
              !isEn ? '/' + lang : ''
            }`,
            component,
            context,
          });
        }
      });
    });
  },
);
