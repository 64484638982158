import React from 'react';
import { Image, Layout } from '../../components';
import * as uuid from 'uuid';
import { Link } from 'gatsby';
import { ServicesListViewProps } from '../../parser';

const ServicesListView: React.FunctionComponent<ServicesListViewProps> = ({
  list,
  page_group,
}) => {
  return (
    <Layout
      title={`Services - ${page_group}`}
      banner={{
        src: '/media/comprehensive-solutions/crm.jpg',
        srcAlt: 'Services',
        subtitle:
          'We bundle our services to create value for your organization',
        title: `Services - ${page_group}`,
      }}
      className="overviewListLayout"
    >
      <div className="overview solutions">
        <div className="wrapper">
          <div className="twoColSingle">
            <div className="twoColSingle--main">
              <ul>
                {list.map((item) => {
                  return (
                    <li key={uuid.v4()}>
                      <Link to={`/${page_group}/${item.slug}`}>
                        <Image
                          src={item.cover_image.uri}
                          alt={item.cover_image.alt_text}
                          minWidth={600}
                        />
                        <div>
                          <h2>{item.title}</h2>
                          <p>{item.overview_text}</p>
                          <div
                            className="btn btn_block"
                            style={{ maxWidth: 'max-content' }}
                          >
                            <span>Learn more</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServicesListView;
