import * as uuid from 'uuid';
import React from 'react';
import { Breadcrumb, Layout, PostList } from '../../components';
import { OurInsightsBlogsProps } from '../../types';
import { PathService } from '../../services';

const OurInsightsBlogsView: React.FunctionComponent<OurInsightsBlogsProps> = ({
  posts,
}) => {
  return (
    <Layout
      title="Blog"
      description="The Pinkerton Blog - trends, analysis and commentary on current events in risk management."
      banner={{
        src: '/media/our-insights/blogs/blog-banner.jpg',
        subtitle: 'Risk management expertise and history',
        title: 'Pinkerton Blog',
      }}
      className="sectionsLayout"
    >
      <div className="overview blogs">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Our insights',
                path: '/our-insights',
              },
              {
                label: 'Blog',
                path: '/our-insights/blog',
              },
            ]}
          />
          <PostList
            list={posts.map((e) => {
              return {
                uuid: uuid.v4(),
                ...e,
              };
            })}
            prefix={PathService.get()}
          />
        </div>
      </div>
    </Layout>
  );
};

export default OurInsightsBlogsView;
