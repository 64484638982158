import React from 'react';
import { HubspotForm, Image, Layout, TagsWidget } from '../../components';
import * as uuid from 'uuid';
import { Link } from 'gatsby';
import { IndustriesListViewProps } from '../../parser';

const IndustriesListView: React.FunctionComponent<IndustriesListViewProps> = ({
  list,
}) => {
  return (
    <Layout
      title="Industries"
      banner={{
        src: '/media/comprehensive-solutions/crm.jpg',
        srcAlt: 'Industries',
        subtitle: 'Comprehensive Services and Solutions for Industries',
        title: 'Industries',
      }}
      className="overviewListLayout"
    >
      <div className="overview solutions">
        <div className="wrapper">
          <div className="twoColSingle">
            <div className="twoColSingle--main">
              <p>
                At Pinkerton, we understand that each industry comes with its
                own set of risks and security needs. That’s why we provide
                tailored, industry-specific services and solutions that
                integrate seamlessly into your organization. Our experts not
                only bring specialized industry knowledge and insights but also
                leverage Pinkerton's extensive security and risk management
                network to safeguard your people, places, and assets.
              </p>
              <h3>Comprehensive Services and Solutions for Industries</h3>
              <p>
                Click on the links below to discover how Pinkerton can help you.
              </p>
              <ul>
                {list.map((item) => {
                  return (
                    <li key={uuid.v4()}>
                      <Link to={item.slug}>
                        {item.cover_image?.uri && (
                          <Image
                            src={item.cover_image.uri}
                            alt={item.cover_image.alt_text}
                            minWidth={600}
                          />
                        )}
                        <div>
                          <h2>{item.title}</h2>
                          <p>{item.overview_text}</p>
                          <div
                            className="btn btn_block"
                            style={{ maxWidth: 'max-content' }}
                          >
                            <span>Learn more</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <HubspotForm formId="ebd435c3-a2e9-4321-bb29-ad241a2bb958" />
              <TagsWidget
                items={[
                  'retail',
                  'organized retail crime',
                  'healthcare',
                  'covid-19',
                  'finance',
                  'banking',
                  'counterfeit goods',
                  'counterfeiting',
                  'counterfeit',
                  'data centers',
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndustriesListView;
