import * as path from 'path';
import { InTheNewsPostContext, InTheNewsPostProps } from '../../types';
import { GeneralUtil } from '../util';
import { BCMSParserFunction } from './parser';

export const InTheNewsParser = BCMSParserFunction(
  async (createPage, _, cache) => {
    const singlePageTemplate = path.join(
      process.cwd(),
      'src',
      'templates',
      'in-the-news',
      'post.tsx',
    );
    cache.in_the_news.forEach((news) => {
      const singlePageProps: InTheNewsPostProps = {
        news,
        relatedNews: GeneralUtil.getRelated(cache.in_the_news, news, 3).map(
          (e) => {
            return {
              title: e.title,
              uri: `/in-the-news/${e.slug}`,
            };
          },
        ),
      };
      const singlePageContext: InTheNewsPostContext = {
        news: JSON.stringify(singlePageProps.news),
        relatedNews: JSON.stringify(singlePageProps.relatedNews),
      };
      createPage({
        component: singlePageTemplate,
        path: `/in-the-news/${news.meta.slug}`,
        context: singlePageContext,
      });
    });
  },
);
