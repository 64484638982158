import { OverviewListViewContext, OverviewListViewProps } from '../..';
import { BCMSParserFunction } from '../parser';

export const OverviewListParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const props: OverviewListViewProps = {
      list: cache.overview.map((e) => {
        return {
          title: e.meta.title,
          cover_image: e.meta.cover_image,
          slug: e.meta.slug,
          overview_text: e.meta.overview_text || '',
        };
      }),
    };
    const context: OverviewListViewContext = {
      list: JSON.stringify(props.list),
    };
    createPage({
      path: '/services',
      component,
      context,
    });
  },
);
