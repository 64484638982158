import {
  PeopleListWidgetType,
  SearchViewContext,
  SearchViewProps,
} from '../../types';
import { findWidget } from '../util';
import { BCMSParserFunction } from './parser';

export const SearchParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const props: SearchViewProps = {
      items: [
        {
          category: '',
          title: `Analysis`,
          description: '',
          uri: `/our-insights/analysis`,
          filterBy: `Analysis`,
        },
        {
          category: '',
          title: `Antipiracy`,
          description:
            'Pinkerton and its subsidiaries own the intellectual property rights to the brand, trademarks and properties popularly associated with the Pinkerton brand.',
          uri: `/antipiracy`,
          filterBy: `Antipiracy`,
        },
        {
          category: '',
          title: `File Disclosure`,
          description:
            'To request a free annual file disclosure please either call us or send us a written request.',
          uri: `/file-disclosure`,
          filterBy: `File Disclosure`,
        },
        {
          category: '',
          title: 'Privacy Policy',
          description:
            'This privacy policy applies solely to information collected when you visit our Website, except where stated otherwise.',
          uri: '/privacy-policy',
          filterBy: 'Privacy Policy',
        },
        {
          category: '',
          title: 'Data Privacy Framework',
          description:
            'This policy describes Pinkerton Consulting and Investigations, Inc. use and treatment of personal information it receives from data subjects.',
          uri: '/data-privacy-framework',
          filterBy: 'Data Privacy Framework',
        },
        {
          category: '',
          title: 'Find Your Pinkerton',
          description: '',
          uri: '//find-your-pinkerton',
          filterBy: 'Find Your Pinkerton',
        },
        {
          category: '',
          title: 'Global Alliance Membership',
          description:
            'Learn more about our GAM program and how to become a certified vendor',
          uri: '/gam',
          filterBy:
            'GAM Global Alliance Membership Learn more about our GAM program and how to become a certified vendor',
        },
        {
          category: '',
          title: 'Videos',
          description:
            'We are the world leaders in comprehensive risk management, providing risk advisory, security management and protective services to global organizations.',
          uri: '/our-insights/video',
          filterBy: 'Our insights Videos',
        },
      ],
    };
    cache.on_demand_webinar.forEach((item) => {
      props.items.push({
        category: 'On Demand Webinar',
        title: item.meta.title,
        description: item.meta.subtitle,
        uri: `/${item.meta.slug}`,
        filterBy: `${item.meta.title} ${item.meta.subtitle}`,
      });

      const peopleListWidget = findWidget<PeopleListWidgetType>(
        item.content,
        'people_list',
      );

      if (peopleListWidget) {
        peopleListWidget.list.forEach((listItem) => {
          props.items.push({
            category: 'People',
            title: listItem.name,
            description: listItem.role,
            uri: `/${item.meta.slug}`,
            filterBy: `People ${listItem.name} ${listItem.role}`,
          });
        });
      }
    });
    cache.consultant_program.forEach((item) => {
      props.items.push({
        category: '',
        title: item.meta.title,
        description:
          'Leverage the Pinkerton global network while growing your business',
        uri: `/${item.meta.slug}`,
        filterBy: `${item.meta.title} Leverage the Pinkerton global network while growing your business`,
      });
    });
    cache.video.forEach((item) => {
      props.items.push({
        category: 'Our insights - Video',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/our-insights/video/${item.meta.slug}`,
        filterBy: `Our insights Videos ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.blogs.forEach((item) => {
      const peopleListWidget = findWidget<PeopleListWidgetType>(
        item.content,
        'people_list',
      );

      if (peopleListWidget) {
        peopleListWidget.list.forEach((listItem) => {
          props.items.push({
            category: 'People',
            title: listItem.name,
            description: listItem.role,
            uri: `/our-insights/blog/${item.meta.slug}`,
            filterBy: `People ${listItem.name} ${listItem.role}`,
          });
        });
      }
    });
    cache.blogs
      .filter((e) => !e.meta.unindexed_and_unreachable)
      .forEach((item) => {
        props.items.push({
          category: 'Our insights - Blogs',
          title: item.meta.title,
          description: item.meta.description,
          uri: `/our-insights/blog/${item.meta.slug}`,
          filterBy: `Our insights Blogs ${item.meta.title} ${item.meta.description}`,
        });
      });
    cache.risk_snapshots.forEach((item) => {
      props.items.push({
        category: 'Our insights - Risk Snapshots',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/our-insights/risk-snapshots/${item.meta.slug}`,
        filterBy: `Our insights Risk Snapshots ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.whitepapers.forEach((item) => {
      props.items.push({
        category: 'Reports',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/reports/${item.meta.slug}`,
        filterBy: `Reports ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.briefings.forEach((item) => {
      props.items.push({
        category: 'Reports',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/reports/${item.meta.slug}`,
        filterBy: `Reports ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.press.forEach((item) => {
      props.items.push({
        category: 'Press',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/press-room/${item.meta.slug}`,
        filterBy: `Press Room ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.media.forEach((item) => {
      props.items.push({
        category: 'Media',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/media/${item.meta.slug}`,
        filterBy: `Media ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.directors.forEach((item) => {
      props.items.push({
        category: `Find your Pinkerton - ${item.meta[
          'en'
        ].region.selected?.replace(/_/g, ' ')}`,
        title: item.meta['en'].title,
        description: ' ',
        uri: `/find-your-pinkerton/${item.meta['en'].slug}`,
        filterBy: `Find your Pinkerton ${item.meta['en'].title} ${item.meta[
          'en'
        ].region.selected?.replace(/_/g, ' ')}`,
      });
    });
    cache.india.forEach((item) => {
      props.items.push({
        category: 'India',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/${item.meta.internal_slug}`,
        filterBy: `India ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.legal.forEach((item) => {
      props.items.push({
        category: 'Legal',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/${item.meta.slug}`,
        filterBy: `Legal ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.overview.forEach((item) => {
      props.items.push({
        category: 'Overview',
        title: item.meta.title,
        description: item.meta.description,
        uri: `/services/${item.meta.slug}`,
        filterBy: `Overview ${item.meta.title} ${item.meta.description}`,
      });
    });
    cache.services.forEach((item) => {
      const formattedGroup = (item.meta.page_group.selected as string)
        .toLowerCase()
        .replace(/ /g, '-');
      props.items.push({
        category: `Services - ${formattedGroup}`,
        title: item.meta.title,
        description: item.meta.description,
        uri: `/${formattedGroup}/${item.meta.slug}`,
        filterBy: `Services ${item.meta.title} ${item.meta.description} ${formattedGroup}`,
      });
    });
    cache.crime_index.forEach((item) => {
      props.items.push({
        category: 'Products',
        title: item.meta.title,
        description: item.meta.hero.description,
        uri: `/products/pinkerton-crime-index/${item.meta.slug}`,
        filterBy: `Products Pinkerton Crime Index ${item.meta.title} ${item.meta.hero.description} ${item.meta.hero.title}`,
      });
    });
    cache.pinkerton_locations.forEach((item) => {
      const langs = Object.keys(item.meta)
        .map((e) => e)
        .filter((e) => item.meta[e].title);
      langs.forEach((lang) => {
        const isEn = lang === 'en';

        props.items.push({
          category: 'Pinkerton Locations',
          title: item.meta[lang].title,
          description: item.meta[lang].description,
          uri: `/${item.meta[lang].slug}${!isEn ? '/' + lang : ''}`,
          filterBy: `Pinkerton Locations ${item.meta[lang].title} ${item.meta[lang].subtitle} ${item.meta[lang].description}`,
        });
      });
    });
    Object.keys(cache.info_pages).forEach((key) => {
      cache.info_pages[key].forEach((infoPage) => {
        const { internal_slug, description, internal_name } = infoPage.meta;
        switch (key) {
          case 'ourStory':
            {
              props.items.push({
                category: 'Our Story',
                title: `Our Story - ${internal_name}`,
                description: infoPage.meta.description,
                uri: `/our-story/${internal_slug.replace('overview', '')}`,
                filterBy: `Our Story ${internal_name} ${description}`,
              });
            }
            break;
          case 'ourServices':
            {
              props.items.push({
                category: 'Our Services',
                title: `Our Services - ${internal_name}`,
                description,
                uri: `/services/${internal_slug.replace('overview', '')}`,
                filterBy: `Our Services ${internal_name} ${description}`,
              });
            }
            break;
        }
      });
    });
    const context: SearchViewContext = {
      items: JSON.stringify(props.items),
    };
    createPage({
      path: '/search',
      component,
      context,
    });
  },
);
