import {
  PostListItem,
  OurInsightsAnalysisViewProps,
  OurInsightsAnalysisViewContext,
} from '../../../types';
import { BCMSParserFunction } from '../parser';

export const OurInsightsAnalysisParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const briefings: PostListItem[] = [];
    const infographics: PostListItem[] = [];
    const perspectivesInCrime: PostListItem[] = [];

    cache.blogs.forEach((blog) => {
      const blogTags = blog.meta.tags;

      blogTags.forEach((tag) => {
        const formattedTag = tag.toLowerCase().trim().replace(/ /g, '-');

        if (formattedTag === 'infographic') {
          infographics.push({
            title: blog.meta.title,
            slug: `our-insights/blog/${blog.meta.slug}`,
            description: blog.meta.description,
            cover_image: blog.meta.cover_image,
            cover_image_alt_text: blog.meta.cover_image_alt_text,
            date: blog.meta.date,
            type: 'blog',
          });
        } else if (formattedTag === 'perspectives-in-crime') {
          perspectivesInCrime.push({
            title: blog.meta.title,
            slug: `our-insights/blog/${blog.meta.slug}`,
            description: blog.meta.description,
            cover_image: blog.meta.cover_image,
            cover_image_alt_text: blog.meta.cover_image_alt_text,
            date: blog.meta.date,
            type: 'blog',
          });
        } else if (formattedTag === 'briefings') {
          briefings.push({
            title: blog.meta.title,
            slug: `our-insights/blog/${blog.meta.slug}`,
            description: blog.meta.description,
            cover_image: blog.meta.cover_image,
            cover_image_alt_text: blog.meta.cover_image_alt_text,
            date: blog.meta.date,
            type: 'blog',
          });
        }
      });

      if (
        blog.meta.featured_analysis &&
        blog.meta.featured_analysis.length > 0
      ) {
        if (blog.meta.featured_analysis[0].section.selected === 'BRIEFINGS') {
          briefings.push({
            title: blog.meta.title,
            slug: `our-insights/blog/${blog.meta.slug}`,
            description: blog.meta.description,
            cover_image: blog.meta.cover_image,
            cover_image_alt_text: blog.meta.cover_image_alt_text,
            date: blog.meta.date,
            type: 'blog',
          });
        } else if (
          blog.meta.featured_analysis[0].section.selected === 'INFOGRAPHICS'
        ) {
          infographics.push({
            title: blog.meta.title,
            slug: `our-insights/blog/${blog.meta.slug}`,
            description: blog.meta.description,
            cover_image: blog.meta.cover_image,
            cover_image_alt_text: blog.meta.cover_image_alt_text,
            date: blog.meta.date,
            type: 'blog',
          });
        } else if (
          blog.meta.featured_analysis[0].section.selected ===
          'COVID_19_INSIGHTS'
        ) {
          perspectivesInCrime.push({
            title: blog.meta.title,
            slug: `our-insights/blog/${blog.meta.slug}`,
            description: blog.meta.description,
            cover_image: blog.meta.cover_image,
            cover_image_alt_text: blog.meta.cover_image_alt_text,
            date: blog.meta.date,
            type: 'blog',
          });
        }
      }
    });

    cache.briefings.forEach((briefing) => {
      const briefingTags = briefing.meta.tags;

      briefingTags.forEach((tag) => {
        const formattedTag = tag.toLowerCase().trim().replace(/ /g, '-');

        if (formattedTag === 'infographic') {
          infographics.push({
            title: briefing.meta.title,
            slug: `reports/${briefing.meta.slug}`,
            description: briefing.meta.description,
            cover_image: briefing.meta.cover_image,
            cover_image_alt_text: briefing.meta.cover_image_alt_text,
            date: briefing.meta.date,
            type: 'briefing',
          });
        } else if (formattedTag === 'perspectives-in-crime') {
          perspectivesInCrime.push({
            title: briefing.meta.title,
            slug: `reports/${briefing.meta.slug}`,
            description: briefing.meta.description,
            cover_image: briefing.meta.cover_image,
            cover_image_alt_text: briefing.meta.cover_image_alt_text,
            date: briefing.meta.date,
            type: 'briefing',
          });
        } else if (formattedTag === 'briefings') {
          briefings.push({
            title: briefing.meta.title,
            slug: `reports/${briefing.meta.slug}`,
            description: briefing.meta.description,
            cover_image: briefing.meta.cover_image,
            cover_image_alt_text: briefing.meta.cover_image_alt_text,
            date: briefing.meta.date,
            type: 'briefing',
          });
        }
      });

      if (
        briefing.meta.featured_analysis &&
        briefing.meta.featured_analysis.length > 0
      ) {
        if (
          briefing.meta.featured_analysis[0].section.selected === 'BRIEFINGS'
        ) {
          briefings.push({
            title: briefing.meta.title,
            slug: `reports/${briefing.meta.slug}`,
            description: briefing.meta.description,
            cover_image: briefing.meta.cover_image,
            cover_image_alt_text: briefing.meta.cover_image_alt_text,
            date: briefing.meta.date,
            type: 'briefing',
          });
        } else if (
          briefing.meta.featured_analysis[0].section.selected === 'INFOGRAPHICS'
        ) {
          infographics.push({
            title: briefing.meta.title,
            slug: `reports/${briefing.meta.slug}`,
            description: briefing.meta.description,
            cover_image: briefing.meta.cover_image,
            cover_image_alt_text: briefing.meta.cover_image_alt_text,
            date: briefing.meta.date,
            type: 'briefing',
          });
        } else if (
          briefing.meta.featured_analysis[0].section.selected ===
          'COVID_19_INSIGHTS'
        ) {
          perspectivesInCrime.push({
            title: briefing.meta.title,
            slug: `reports/${briefing.meta.slug}`,
            description: briefing.meta.description,
            cover_image: briefing.meta.cover_image,
            cover_image_alt_text: briefing.meta.cover_image_alt_text,
            date: briefing.meta.date,
            type: 'briefing',
          });
        }
      }
    });
    const props: OurInsightsAnalysisViewProps = {
      infographics: infographics
        .filter(
          (item, index, self) =>
            index === self.findIndex((e) => e.slug === item.slug),
        )
        .sort((a, b) => (b.date || 0) - (a.date || 0)),
      perspectivesInCrime: perspectivesInCrime
        .filter(
          (item, index, self) =>
            index === self.findIndex((e) => e.slug === item.slug),
        )
        .sort((a, b) => (b.date || 0) - (a.date || 0)),
      briefings: briefings
        .filter(
          (item, index, self) =>
            index === self.findIndex((e) => e.slug === item.slug),
        )
        .sort((a, b) => (b.date || 0) - (a.date || 0)),
    };
    const context: OurInsightsAnalysisViewContext = {
      briefings: JSON.stringify(props.briefings),
      perspectivesInCrime: JSON.stringify(props.perspectivesInCrime),
      infographics: JSON.stringify(props.infographics),
    };
    createPage({
      path: `/our-insights/analysis`,
      component,
      context,
    });
  },
);
