import { VideosViewContext, VideosViewProps } from '../..';
import { BCMSParserFunction } from '../parser';

export const VideosParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const props: VideosViewProps = {
      videos: cache.video.map((video) => {
        return {
          title: video.meta.title,
          slug: video.meta.slug,
          description: video.meta.description,
          cover_image: video.meta.cover_image,
          date: video.meta.date,
          tags: video.meta.tags,
          video_url: video.meta.video_url,
        };
      }),
    };
    const context: VideosViewContext = {
      videos: JSON.stringify(props.videos),
    };
    createPage({
      path: '/our-insights/video',
      component,
      context,
    });
  },
);
