import { OurServicesPostProps, OutServicesPostContext } from '../../../types';
import { BCMSParserFunction } from '../parser';

export const OurServicePostParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.info_pages.ourServices.forEach((page) => {
      const props: OurServicesPostProps = {
        page,
        sideNav: cache.info_pages.ourServices.map((service) => {
          return {
            title: service.meta.internal_name,
            subtitle: service.meta.subtitle,
            slug:
              service.meta.internal_slug !== 'overview'
                ? service.meta.internal_slug
                : '',
            subNavItems: service.meta.sub_side_nav,
          };
        }),
      };
      const context: OutServicesPostContext = {
        page: JSON.stringify(props.page),
        sideNav: JSON.stringify(props.sideNav),
      };
      // TODO
      // createPage({
      //   component,
      //   path: `/our-services${
      //     page.meta.internal_slug !== 'overview'
      //       ? '/' + page.meta.internal_slug
      //       : ''
      //   }`,
      //   context,
      // });
    });
  },
);
