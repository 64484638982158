import React from 'react';
import { Article, Aside, Breadcrumb, Layout } from '../../components';
import { PressRoomPostProps } from '../../types';

const PressRoomPostView: React.FunctionComponent<PressRoomPostProps> = ({
  press,
  relatedPress,
}) => {
  return (
    <Layout
      className="articleWithPatternLayout"
      title={press.meta.title}
      description={press.meta.description}
      banner={{
        src: press.meta.cover_image
          ? press.meta.cover_image
          : '/media/press-and-events/events/banner.jpg',
        srcAlt: press.meta.cover_image_alt_text
          ? press.meta.cover_image_alt_text
          : press.meta.title,
        subtitle: press.meta.subtitle,
        title: press.meta.title,
      }}
    >
      <div className="overview press">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Press Room',
                path: '/press-room',
              },
            ]}
          />
          <div className="twoColSingle">
            <Article date={press.meta.date} content={press.content} />
            {relatedPress.length > 0 ? (
              <Aside
                list={[
                  {
                    group: {
                      name: 'Related Press',
                      items: relatedPress,
                    },
                  },
                ]}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PressRoomPostView;
