import React from 'react';
import {
  Article,
  AsideWithIcons,
  Breadcrumb,
  Layout,
  WidgetManager,
} from '../../components';
import { PathService } from '../../services';
import { PropType, ServiceViewProps } from '../../types';

const ServicesView: React.FunctionComponent<ServiceViewProps> = ({
  page,
  content,
}) => {
  const widgetsToFilter = [
    'interested_in',
    'reference',
    'cta_large',
    'columns_with_icons',
    'content_with_visual',
    'tags',
    'table',
  ];

  const filteredContent = content.filter(
    (e) => !widgetsToFilter.includes(e.name),
  );

  const largeWidgets = content.filter((e) => widgetsToFilter.includes(e.name));

  return (
    <Layout
      banner={{
        src: page.cover_image.uri,
        title: page.title,
        srcAlt: page.cover_image.alt_text,
        subtitle: page.subtitle,
      }}
      description={page.description}
      title={page.title}
      OGImageUri={page.cover_image.uri}
      className="articleWithPatternLayout"
    >
      <div className="overview solution">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: page.page_group,
                path: `/${page.page_group}`,
              },
              {
                label: page.title,
                path: PathService.get(),
              },
            ]}
          />
          {page.introduction_text &&
            page.introduction_text
              .replace(/<(\/)?div([^>]*)>/g, '')
              .replace(/<(\/)?p([^>]*)>/g, '')
              .replace(/<(\/)?strong([^>]*)>/g, '')
              .replace(/<(\/)?br([^>]*)>/g, '') && (
              <div
                className="solution--introductionText"
                dangerouslySetInnerHTML={{ __html: page.introduction_text }}
              />
            )}
          <div className="twoColSingle">
            <Article content={filteredContent} />
            {page.aside?.title && <AsideWithIcons data={page.aside} />}
          </div>
        </div>
        {largeWidgets.map((contentItem, contentItemIndex) => {
          switch (contentItem.type) {
            case PropType.WIDGET: {
              return (
                <WidgetManager
                  key={'' + contentItemIndex}
                  widget={contentItem}
                ></WidgetManager>
              );
            }
          }
        })}
      </div>
    </Layout>
  );
};

export default ServicesView;
