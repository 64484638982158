import React from 'react';
import { OnDemandWebinarViewProps } from './../types';
import { Article, AsideHubspotForm, Layout } from './../components';

const OnDemandWebinarView: React.FunctionComponent<OnDemandWebinarViewProps> = ({
  page,
  content,
}) => {
  return (
    <Layout
      title={page.title}
      banner={{
        src: page.cover_image.uri,
        srcAlt: page.cover_image.alt_text,
        subtitle: page.subtitle,
        title: page.title,
      }}
      className="articleWithAsideLayout"
    >
      <div className="overview onDemandWebinar">
        <div className="wrapper">
          <div className="twoColSingle">
            <Article content={content} />
            <AsideHubspotForm
              title={page.aside_hubspot.title}
              dark={page.aside_hubspot.dark}
              form_id={page.aside_hubspot.form_id}
              className="twoColSingle--aside"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OnDemandWebinarView;
