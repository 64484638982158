/* eslint-disable react/no-unescaped-entities */
import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../components';
import {
  CareerOpeningsViewProps,
  CareerOpeningsViewPropsJob,
} from '../../types';

const CareerOpeningsView: React.FunctionComponent<CareerOpeningsViewProps> = ({
  jobCountries,
  jobTypes,
  jobs,
}) => {
  const [filteredJobs, setFilteredJobs] =
    React.useState<CareerOpeningsViewPropsJob[]>(jobs);
  const [selectedType, setSelectedType] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [showCategoryIndex, setShowCategoryIndex] = React.useState(-1);

  function filterJobs(type?: string, country?: string) {
    if (typeof type !== 'undefined') {
      setSelectedType(type);
    } else {
      type = selectedType;
    }
    if (typeof country !== 'undefined') {
      setSelectedCountry(country);
    } else {
      country = selectedCountry;
    }
    setFilteredJobs(
      jobs
        .map((job) => {
          const filteredOpenings = job.openings.filter((opening) => {
            let pass = true;

            if (type !== '' && opening.type !== type) {
              pass = false;
            }

            if (country?.startsWith('United States')) {
              if (country === 'United States' && opening.country !== country) {
                pass = false;
              } else if (
                country !== 'United States' &&
                country !== `${opening.country} - ${opening.state}`
              ) {
                pass = false;
              }
            } else if (country !== '' && opening.country !== country) {
              pass = false;
            }

            return pass;
          });
          return {
            category: job.category,
            openings: filteredOpenings,
          };
        })
        .filter((job) => job.openings.length > 0),
    );
  }

  return (
    <Layout
      title="Careers | Current Openings"
      description=""
      banner={{
        src: '/media/careers/careers.jpg',
        subtitle: 'Explore our current job openings',
        title: 'Pinkerton Careers',
      }}
      className="articleWithAsideLayout"
    >
      <div className="overview currentOpenings">
        <div className="wrapper">
          <header className="overview--header">
            <h2 className="overview--header-title">Why Choose Pinkerton</h2>
            <p className="overview--header-description">
              For many, Pinkerton is a company that answers a calling. A calling
              to serve and protect others, no matter what. With this unified
              sense of purpose, our global force of Pinkerton agents and support
              functions band together and become something more than colleagues
              — they become a family. No matter how far they are spread apart,
              Pinkertons are bonded together by their shared calling and their
              commitment to integrity, vigilance, and excellence.
            </p>
          </header>

          <div className="currentOpenings--jobsFilter">
            <span>I'M INTERESTED IN</span>
            <div>
              <select
                onChange={(event) => {
                  const element = event.target as HTMLSelectElement;
                  filterJobs(element.value);
                }}
                name="job-type"
              >
                <option value="">All Job Types</option>
                {jobTypes.map((type, index) => {
                  return (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  );
                })}
              </select>
            </div>
            <span>IN</span>
            <div>
              <select
                onChange={(event) => {
                  const element = event.target as HTMLSelectElement;
                  filterJobs(undefined, element.value);
                }}
                name="country"
              >
                <option value="">All Countries</option>
                {jobCountries.map((country, index) => {
                  return (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="articleWithPatternLayout">
          <div className="overview">
            <div className="wrapper">
              <div className="twoColSingle">
                <div className="twoColSingle--main">
                  {filteredJobs.length > 0 ? (
                    <ul className="accordion--categories-list">
                      {filteredJobs.map((job, jobIndex) => {
                        return (
                          <li
                            className={`accordion--categories-item accordion ${
                              showCategoryIndex === jobIndex ? 'expand' : ''
                            }`}
                            key={jobIndex}
                          >
                            <button
                              onClick={() => {
                                if (showCategoryIndex === jobIndex) {
                                  setShowCategoryIndex(-1);
                                } else {
                                  setShowCategoryIndex(jobIndex);
                                }
                              }}
                              className="btn btn_block accordion--btn"
                            >
                              <span>{job.category}</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-plus"
                              >
                                <path d="M12 5v14M5 12h14" />
                              </svg>
                            </button>
                            <ul className="accordion--jobs-list">
                              {job.openings.map((opening, openingIndex) => {
                                return (
                                  <li
                                    data-type={opening.type
                                      .toLowerCase()
                                      .split(' ')
                                      .join('-')}
                                    data-country={opening.country
                                      .toLowerCase()
                                      .split(' ')
                                      .join('-')}
                                    className="accordion--jobs-item"
                                    key={openingIndex}
                                  >
                                    <Link
                                      to={`/job/${
                                        opening.referenceNumber
                                      }/${opening.title
                                        .toLowerCase()
                                        .replace(/ /g, '-')
                                        .replace(/_/g, '-')
                                        .replace(/--/g, '-')
                                        .replace(/[^a-z0-9 - ---]/g, '')}`}
                                    >
                                      <h4 className="accordion--jobs-title">
                                        {opening.title}
                                      </h4>
                                      <p className="accordion--jobs-location">
                                        {opening.city ? opening.city : ''}
                                        {opening.city && opening.state
                                          ? ', '
                                          : ''}
                                        {opening.state ? opening.state : ''} -{' '}
                                        {opening.country}
                                      </p>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="empty">
                      No open {selectedType} positions in {selectedCountry}
                    </div>
                  )}
                  <div className="currentOpenings--cta">
                    <h3 className="currentOpenings--cta-title">
                      Pinkerton's talent portal
                    </h3>
                    <p className="currentOpenings--cta-description">
                      Apply directly to one of Pinkerton’s openings above or
                      join the Talent Network below. To update your resume in
                      the system, please click the returning applicant button.
                      We look forward to connecting with you!
                    </p>
                    <a
                      href="https://www.pinkertonhr.com/careers/job.aspx?CompanyID=PINKERTON&Website=Careers&RequisitionID=1132&Source=Company%20Website&CurrentCompany=1&ApplyNow=External"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn_block currentOpenings--cta-btn"
                      style={{
                        marginRight: '10px',
                      }}
                    >
                      <span>Join network</span>
                    </a>
                    <a
                      href="https://www.pinkertonhr.com/Careers/Login.aspx?CompanyID=PINKERTON&GUID=b53eb970-8e38-4164-89e6-227a623cd118"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn_block currentOpenings--cta-btn"
                    >
                      <span>Returning applicant</span>
                    </a>
                  </div>
                </div>
                <aside className="twoColSingle--aside currentOpenings--aside">
                  <p className="currentOpenings--aside-text">
                    <em>
                      Pinkerton is an equal opportunity employer to all
                      applicants and positions without regard to race/ethnicity,
                      color, national origin, ancestry, sex/gender, gender
                      identity/expression, sexual orientation, marital/prenatal
                      status, pregnancy/childbirth or related conditions,
                      religion, creed, age, disability, genetic information,
                      veteran status, or any protected status by local, state,
                      federal or country-specific law.
                    </em>
                  </p>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CareerOpeningsView;
