import {
  IndiaPostViewContext,
  IndiaPostViewProps,
  SideNavComponentItem,
} from '../../../types';
import { BCMSParserFunction } from '../parser';

export const IndiaPostParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const sideNavItems: SideNavComponentItem[] = cache.india.map((page) => {
      return {
        slug: page.meta.internal_slug,
        subNavItems: [],
        title: page.meta.internal_slug === 'in' ? 'Overview' : page.meta.title,
      };
    });

    cache.india.forEach((post) => {
      const props: IndiaPostViewProps = {
        page: {
          title: post.meta.title,
          subtitle: post.meta.subtitle,
          description: post.meta.description,
          cover_image: post.meta.cover_image,
          internal_slug: post.meta.internal_slug,
        },
        content: post.content,
        sideNavItems: [
          ...sideNavItems.filter((e) => e.title === 'Overview'),
          ...sideNavItems.filter(
            (e) => e.title === 'Background Checks - India',
          ),
          ...sideNavItems.filter(
            (e) =>
              e.title !== 'Background Checks - India' && e.title !== 'Overview',
          ),
        ],
      };
      const context: IndiaPostViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
        sideNavItems: JSON.stringify(props.sideNavItems),
      };
      createPage({
        path: `/${post.meta.internal_slug}`,
        component,
        context,
      });
    });
  },
);
