import { Link } from 'gatsby';
import React from 'react';
import { Image, Layout } from '../../components';
import { FindARiskAdvisorOverviewViewProps } from '../../types';

const FindARiskAdvisorOverviewView: React.FunctionComponent<
  FindARiskAdvisorOverviewViewProps
> = ({ regions }) => {
  const [activeFilter, setActiveFilter] = React.useState('');
  const [filteredRegions, setFilteredRegions] = React.useState(regions);

  React.useEffect(() => {
    if (activeFilter) {
      setFilteredRegions(
        regions.filter((region) => {
          return region.name === activeFilter;
        }),
      );
    }
  }, [activeFilter]);

  React.useEffect(() => {
    const routeHash = window.location.hash.slice(1);
    if (routeHash) {
      setActiveFilter(routeHash.toUpperCase().replace('-', ' '));
    }
  }, []);

  return (
    <Layout title="Find your Pinkerton" description="">
      <div className="overview risk-advisor risk-advisor_overview">
        <div className="wrapper">
          <h1 className="risk-advisor_overview-title">
            <span>Find Your</span>
            <span>Pinkerton</span>
          </h1>
          <div className="risk-advisor_overview-indent">
            <p className="overview--header-description">
              Our advisors are specialized experts, who work closely with you to
              meet the needs of your organization. They're here to assess,
              design, and deliver the right solution for you — whether you're in
              the market for risk management services or custom security
              solutions.
            </p>
            <p className="overview--header-description">
              Risk advisors are your gateway to coverage. With resources in over
              100 countries, we can provide local access, while delivering
              globally. With your personal risk advisor, you'll be able to
              access our global platform, providing unprecendented comprehensive
              risk management.
            </p>
            <div className="risk-advisor_overview-filters">
              <h3 className="risk-advisor_overview-filters-title">
                Filter by region
              </h3>
              <div className="risk-advisor_overview-filters-list">
                {regions.map((region, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        setActiveFilter(region.name);
                      }}
                      className={`${
                        activeFilter === region.name ? 'active' : ''
                      }`}
                    >
                      <span>{region.name}</span>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="risk-advisor_overview-regions">
              {filteredRegions.map((region, index) => {
                return (
                  <div key={index} className="risk-advisor_overview-region">
                    <h2 className="risk-advisor_overview-region-title">
                      {region.name}
                    </h2>
                    {region.directors.map((director, id) => {
                      return (
                        <div
                          className="risk-advisor_overview-regionItem"
                          key={id}
                        >
                          <div className="risk-advisor_overview-regionItem-img">
                            <Image src={director.img} alt={director.title} />
                          </div>
                          <div className="risk-advisor_overview-regionItem-content">
                            <span className="risk-advisor_overview-regionItem-title">
                              {director.title}
                            </span>
                            <div className="risk-advisor_overview-regionItem-description">
                              <span>{director.position}</span>{' '}
                              <Link
                                to={`/find-your-pinkerton/${director.slug}`}
                              >
                                View profile {'>>'}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FindARiskAdvisorOverviewView;
