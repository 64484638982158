import {
  OurInsightsBlogViewContext,
  OurInsightsBlogViewProps,
} from '../../../types';
import { GeneralUtil } from '../../util';
import { BCMSParserFunction } from '../parser';

export const OurInsightsBlogParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.blogs.forEach((blog) => {
      const props: OurInsightsBlogViewProps = {
        page: {
          title: blog.meta.title,
          subtitle: blog.meta.subtitle,
          cover_image: blog.meta.cover_image,
          cover_image_alt_text: blog.meta.cover_image_alt_text,
          description: blog.meta.description,
          date: blog.meta.date,
          tags: blog.meta.tags,
          unindexed_and_unreachable: blog.meta.unindexed_and_unreachable,
        },
        content: blog.content,
        relatedPosts: GeneralUtil.getRelated(cache.blogs, blog, 5),
      };
      const context: OurInsightsBlogViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
        relatedPosts: JSON.stringify(props.relatedPosts),
      };
      createPage({
        path: `/our-insights/blog/${blog.meta.slug}`,
        component,
        context,
      });
    });
  },
);
