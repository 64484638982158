import React from 'react';
import { IndustryViewProps } from '../../parser';
import {
  Layout,
  Article,
  CrimeIndexHero,
  CrimeIndexRelatedByTag,
} from '../../components';

const IndustryView: React.FunctionComponent<IndustryViewProps> = ({
  page,
  content,
}) => {
  return (
    <Layout
      description={page.hero.description}
      title={page.title}
      OGImageUri={page.hero.image.uri}
      className={`industryPage ${
        page.hero.full_width_image ? 'fullWidthBanner' : ''
      } ${page.slug.toLowerCase().replace(/ /g, '-')}`}
      noPinkertonInTitle={true}
    >
      <CrimeIndexHero hero={page.hero} dark={false} twoCol={false} />
      <Article content={content} fullWidth={true} />
      {page.related.items.length > 0 && (
        <CrimeIndexRelatedByTag
          title={page.related.title}
          items={page.related.items}
        />
      )}
    </Layout>
  );
};

export default IndustryView;
