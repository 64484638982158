import React from 'react';
import { ContactUsViewProps } from './../types';
import { Article, Layout } from './../components';

const ContactUsView: React.FunctionComponent<ContactUsViewProps> = ({
  page,
  content,
}) => {
  return (
    <Layout
      title={page.title}
      description={page.description}
      banner={{
        src: page.cover_image
          ? page.cover_image
          : '/media/our-story/banner.jpg',
        srcAlt: page.cover_image_alt_text
          ? page.cover_image_alt_text
          : page.title,
        subtitle: page.subtitle,
        title: page.title,
      }}
      className="articleWithPatternLayout"
    >
      <div className="overview contactUs">
        <div className="wrapper">
          <div className="twoColSingle">
            <Article content={content} />
            <ul className="contactUs--socialList">
              <li className="contactUs--socialList-item">
                <a
                  href="https://www.linkedin.com/company/pinkerton-corporate-risk-management/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="svg-inline--fa fa-linkedin-in fa-w-14"
                    aria-hidden="true"
                    data-icon="linkedin-in"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z"
                    ></path>
                  </svg>
                  <span className="sr-only">Pinkerton on Linkedin</span>
                </a>
              </li>
              <li className="contactUs--socialList-item">
                <a
                  href="https://twitter.com/Pinkerton"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    width="16"
                    height="17"
                    className="svg-inline--fa fa-twitter fa-w-16"
                    aria-hidden="true"
                    data-icon="twitter"
                    role="img"
                    viewBox="0 0 1200 1227"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="sr-only">Pinkerton on Twitter</span>
                </a>
              </li>
              <li className="contactUs--socialList-item">
                <a
                  href="https://www.facebook.com/pinkertoncorporate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="svg-inline--fa fa-facebook-f fa-w-9"
                    aria-hidden="true"
                    data-icon="facebook-f"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 264 512"
                  >
                    <path
                      fill="currentColor"
                      d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
                    ></path>
                  </svg>
                  <span className="sr-only">Pinkerton on Facebook</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsView;
