import React from 'react';
import { Article, Layout, SideNav, Breadcrumb } from '../../components';
import { IndiaPostViewProps } from '../../types';

const IndiaPostView: React.FunctionComponent<IndiaPostViewProps> = ({
  page,
  content,
  sideNavItems,
}) => {
  return (
    <Layout
      title={page.title}
      description={page.description}
      banner={{
        src: page.cover_image,
        subtitle: page.subtitle,
        title: page.title,
      }}
      OGImageUri={page.cover_image}
      className="articleWithAsideLayout"
    >
      <div className="overview india">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'India',
                path: '/in',
              },
              {
                label: page.title,
                path: `/${page.internal_slug}`,
              },
            ]}
          />
          <div className="twoColSingle">
            <Article content={content} />
            <SideNav
              baseUri=""
              pathname={page.internal_slug}
              items={sideNavItems}
              isSticky={true}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndiaPostView;
