/* eslint-disable react/no-unescaped-entities */
import * as uuid from 'uuid';
import React from 'react';
import { OurInsightsOverviewViewProps } from '../../types';
import { Layout, PostList, DoubleBannersWidget } from '../../components';
import { Link } from 'gatsby';
import { PathService } from '../../services';

const OurInsightsOverviewView: React.FunctionComponent<OurInsightsOverviewViewProps> = ({
  blogs,
  whitepapers,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout
      title="Our Insights"
      description="Our insights, including infographics, blog, whitepapers and analysis on current risk trends."
      banner={{
        src: '/media/our-insights/our-insights-banner.jpg',
        subtitle: 'An overview into our thought leadership',
        title: 'Our Insights',
      }}
      className="sectionsLayout"
    >
      <div className="overview our-insights">
        <div className="wrapper">
          <header className="overview--header">
            <h2 className="overview--header-description">
              We're constantly learning, educating, analyzing and sharing our
              expertise in an effort to push thought leadership in the risk
              management industry forward.
            </h2>
            <p className="overview--section-description">
              While Pinkerton has been around for 170 years, we pride ourselves
              on utilizing a start-up mentality. It's this kind of comprehensive
              risk management thought leadership that has helped Pinkerton
              become a global force in the risk management services space.
            </p>
          </header>
        </div>

        <div className="wrapper">
          <hr />

          <section className="overview--section">
            <h3 className="overview--section-title">Featured White Papers</h3>
            <p className="overview--section-description">
              Learn about our global intelligence and technologically advanced
              methodologies in these thought-provoking and insightful white
              papers.
            </p>
            <PostList
              list={whitepapers.map((e) => {
                return {
                  uuid: uuid.v4(),
                  ...e,
                };
              })}
              prefix="/reports"
            />
            <Link className="btn btn_block" to="/reports">
              <span>View All</span>
            </Link>
          </section>

          <hr />

          <section className="overview--section">
            <h3 className="overview--section-title">Recent Blog Posts</h3>
            <p className="overview--section-description">
              Discover the latest news, trends & solutions, with our expert
              insight & analysis, for the global comprehensive risk management
              landscape.
            </p>
            <PostList
              list={blogs.map((e) => {
                return {
                  uuid: uuid.v4(),
                  ...e,
                };
              })}
              prefix={`${PathService.get()}/blog`}
            />
            <Link className="btn btn_block" to="/our-insights/blog/">
              <span>View All</span>
            </Link>
          </section>
        </div>
        <div className="wrapper">
          <section className="overview--section">
            <h3 className="overview--section-title">Pinkerton Analysis</h3>
            <p className="overview--section-description">
              Learn about our expert analyses, guided by technology and machine
              learning...
            </p>
            <Link className="btn btn_block" to="/our-insights/analysis/">
              <span>Discover Now</span>
            </Link>
          </section>
        </div>

        <DoubleBannersWidget
          items={[
            {
              background_image: '/media/home/nyc.jpeg',
              subtitle: 'Products',
              title:
                'Explore innovative crime reporting with Pinkerton Crime Index',
              call_to_action: {
                label: 'Discover Now',
                link_to: '/products/pinkerton-crime-index',
              },
            },
            {
              background_image:
                '/media/global/doublebanners/card-for-our-services.png',
              subtitle: 'Our services',
              title:
                'We help organizations identify, mitigate & manage business risks and security concerns anywhere in the world.',
              call_to_action: {
                label: 'Discover How',
                link_to: '/services',
              },
            },
          ]}
        />
      </div>
    </Layout>
  );
};

export default OurInsightsOverviewView;
