import {
  CrimeIndexViewContext,
  CrimeIndexViewProps,
  RelatedByTagWidgetType,
} from '..';
import { RelatedItemOutput } from '../util';
import { BCMSParserFunction } from './parser';

export const CrimeIndexParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const blogs = cache.blogs;

    cache.crime_index.forEach((entry) => {
      let relatedItems: RelatedItemOutput[] = [];

      const relatedByTagWidget = entry.content.find(
        (e) => e.name === 'related_by_tag',
      );

      if (relatedByTagWidget) {
        const results: RelatedItemOutput[] = [];
        const tags = (
          relatedByTagWidget.value as unknown as RelatedByTagWidgetType
        ).tags;

        blogs
          .sort((a, b) => b.meta.date - a.meta.date)
          .forEach((blog) => {
            if (blog.meta.tags.find((tag) => tags.includes(tag))) {
              results.push({
                _id: blog._id,
                slug: `/our-insights/blog/${blog.meta.slug}`,
                title: blog.meta.title,
                description: blog.meta.description,
                image: {
                  uri: blog.meta.cover_image,
                  alt_text: blog.meta.cover_image_alt_text,
                },
              });
            }
          });
        if (results.length < 3) {
          const whitepapers = cache.whitepapers;

          whitepapers
            .sort((a, b) => b.meta.date - a.meta.date)
            .forEach((whitepaper) => {
              if (whitepaper.meta.tags.find((tag) => tags.includes(tag))) {
                results.push({
                  _id: whitepaper._id,
                  slug: `/reports/${whitepaper.meta.slug}`,
                  title: whitepaper.meta.title,
                  description: whitepaper.meta.description,
                  image: {
                    uri: whitepaper.meta.cover_image,
                    alt_text: whitepaper.meta.cover_image_alt_text,
                  },
                });
              }
            });
        }
        if (results.length < 3) {
          const briefings = cache.briefings;

          briefings
            .sort((a, b) => b.meta.date - a.meta.date)
            .forEach((briefing) => {
              if (briefing.meta.tags.find((tag) => tags.includes(tag))) {
                results.push({
                  _id: briefing._id,
                  slug: `/reports/${briefing.meta.slug}`,
                  title: briefing.meta.title,
                  description: briefing.meta.description,
                  image: {
                    uri: briefing.meta.cover_image,
                    alt_text: briefing.meta.cover_image_alt_text,
                  },
                });
              }
            });
        }

        if (results.length < 3) {
          const press = cache.press;

          press
            .sort((a, b) => b.meta.date - a.meta.date)
            .forEach((entry) => {
              if (entry.meta.tags.find((tag) => tags.includes(tag))) {
                results.push({
                  _id: entry._id,
                  slug: `/press-room/${entry.meta.slug}`,
                  title: entry.meta.title,
                  description: entry.meta.description,
                  image: {
                    uri: entry.meta.cover_image,
                    alt_text: entry.meta.cover_image_alt_text,
                  },
                });
              }
            });
        }

        relatedItems = results.slice(0, 3);
      }

      const props: CrimeIndexViewProps = {
        page: {
          title: entry.meta.title,
          hero: entry.meta.hero,
          two_column_layout: entry.meta.two_column_layout,
          dark: entry.meta.dark,
          related: {
            title:
              (relatedByTagWidget?.value as unknown as RelatedByTagWidgetType)
                ?.title || '',
            items: relatedItems,
          },
          hubspot: entry.meta.hubspot,
          slug: entry.meta.slug,
        },
        content: entry.content.filter((e) => e.name !== 'related_by_tag'),
      };
      const context: CrimeIndexViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        path: `/products/pinkerton-crime-index/${entry.meta.slug}`,
        component,
        context,
      });
    });
  },
);
