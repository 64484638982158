import React from 'react';
import { Article, Aside, Breadcrumb, Layout } from '../../components';
import { InTheNewsPostProps } from '../../types';

const InTheNewsPostView: React.FunctionComponent<InTheNewsPostProps> = ({
  news,
  relatedNews,
}) => {
  return (
    <Layout
      title={news.meta.title}
      description={news.meta.description}
      banner={{
        src: news.meta.cover_image
          ? news.meta.cover_image
          : '/media/in-the-news/inthenews-banner.jpg',
        srcAlt: news.meta.cover_image_alt_text
          ? news.meta.cover_image_alt_text
          : news.meta.title,
        subtitle: news.meta.subtitle,
        title: news.meta.title,
      }}
    >
      <div className="overview press">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'In the News',
                path: '/press-room',
              },
            ]}
          />
          <div className="twoColSingle">
            <Article date={news.meta.date} content={news.content} />
            {relatedNews.length > 0 ? (
              <Aside
                list={[
                  {
                    group: {
                      name: 'Related News',
                      items: relatedNews,
                    },
                  },
                ]}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InTheNewsPostView;
