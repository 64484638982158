import React from 'react';
import {
  OurStoryViewProps,
  HistoryTimelineWidget as HistoryTimelineWidgetType,
} from '../../types';
import {
  Layout,
  Breadcrumb,
  Article,
  SideNav,
  DoubleBannersWidget,
  HistoryTimelineWidget,
} from '../../components';

const OurStoryPostView: React.FunctionComponent<OurStoryViewProps> = ({
  page,
  sideNav,
  content,
}) => {
  const historyTimelineWidget = content.find(
    (e) => e.name === 'history_timeline',
  );

  return (
    <Layout
      title="Our Story"
      description=""
      banner={{
        src: page.cover_image
          ? page.cover_image
          : '/media/our-story/ourstory-history.jpg',
        srcAlt: page.cover_image_alt_text
          ? page.cover_image_alt_text
          : page.title,
        subtitle: page.subtitle,
        title: page.title,
      }}
      className="articleWithAsideLayout"
    >
      <div className="overview our-story">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Our Story',
                path: '/our-story',
              },
            ]}
          />
          <div className="twoColSingle">
            <Article
              content={content.filter((e) => e.name !== 'history_timeline')}
            />
            <SideNav
              title=""
              pathname={page.internal_slug}
              baseUri="/our-story"
              items={sideNav}
              isSticky={true}
            />
          </div>
          {page.internal_slug === 'history' && historyTimelineWidget && (
            <HistoryTimelineWidget
              items={
                ((historyTimelineWidget.value as unknown) as HistoryTimelineWidgetType)
                  .items
              }
            />
          )}
        </div>
        <DoubleBannersWidget
          items={[
            {
              background_image: '/media/home/nyc.jpeg',
              subtitle: 'Products',
              title:
                'Explore innovative crime reporting with Pinkerton Crime Index',
              call_to_action: {
                label: 'Discover Now',
                link_to: '/products/pinkerton-crime-index',
              },
            },
            {
              background_image:
                '/media/global/doublebanners/card-for-our-services.png',
              subtitle: 'Our Services',
              title:
                'We help organizations identify, mitigate & manage business risks and security concerns anywhere in the world.',
              call_to_action: {
                label: 'Discover How',
                link_to: '/services',
              },
            },
          ]}
        />
      </div>
    </Layout>
  );
};

export default OurStoryPostView;
