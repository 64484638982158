import React from 'react';
import { Helmet } from 'react-helmet';
import { Image, Layout } from '../components';
import { HomeViewProps } from '../parser';
import { Link } from 'gatsby';

const HomeView: React.FunctionComponent<HomeViewProps> = ({ page }) => {
  return (
    <Layout
      className="home"
      title={page.title}
      OGImageUri="/media/home/ourstory.jpg"
    >
      <Helmet>
        <link href="/media/home/hero-1920.jpg" rel="preload" as="image" />
      </Helmet>
      {page.trending && (
        <section className="home--hero">
          <div className="wrapper">
            <Link to={page.trending.slug}>
              <div className="home--hero-trendingTitle">Trending</div>
              <h2 className="home--hero-title">{page.trending.title}</h2>
              <p className="home--hero-description">
                {page.trending.description}
              </p>
            </Link>
            <Link
              to={page.trending.slug}
              className="btn btn_block darkBg home--hero-cta"
            >
              <span>Learn more</span>
            </Link>
          </div>
          <Image
            src={page.trending.image}
            alt={page.trending.title}
            minWidth={1600}
            className="home--hero-bg"
          />
        </section>
      )}
      {page.hero && !page.trending && (
        <section className="home--hero">
          <div className="wrapper">
            <Link to={page.hero.link_url}>
              <h2 className="home--hero-title">{page.hero.title}</h2>
              <p className="home--hero-description">{page.hero.description}</p>
            </Link>
            <Link
              to={page.hero.link_url}
              className="btn btn_block darkBg home--hero-cta"
            >
              <span>{page.hero.link_label}</span>
            </Link>
          </div>
          <Image
            src={page.hero.image.uri}
            alt={page.hero.image.alt_text}
            minWidth={1600}
            className="home--hero-bg"
          />
        </section>
      )}
      <section className="home--spotlights">
        <div className="wrapper">
          <h2 className="home--spotlights-title">{page.spotlights_heading}</h2>
          <div className="home--spotlights-items">
            {[...page.spotlights, ...page.spotlights, ...page.spotlights]
              .slice(0, 3)
              .map((spotlight, index) => {
                return (
                  <Link
                    to={spotlight.slug}
                    className="home--spotlights-item"
                    key={index}
                  >
                    <div className="home--spotlights-item-iconWrapper">
                      {spotlight.icon && (
                        <Image
                          src={spotlight.icon}
                          alt={spotlight.title}
                          className="icon"
                        />
                      )}
                    </div>
                    <h3 className="home--spotlights-item-title">
                      {spotlight.title}
                    </h3>
                    <p className="home--spotlights-item-description">
                      {spotlight.description}
                    </p>
                    <div className="home--spotlights-item-learnMore">
                      Learn more {'>>'}
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </section>
      <section className="home--links">
        <div className="wrapper">
          {page.link_groups.map((group, index) => {
            return (
              <div className="home--links-group" key={index}>
                <Link to={group.link_url} className="home--links-group-top">
                  <div className="home--links-group-top-text">
                    <h2 className="home--links-group-label">{group.label}</h2>
                    <p className="home--links-group-description">
                      {group.description}
                    </p>
                  </div>
                  <div className="home--links-group-top-iconWrapper">
                    <Image
                      src={group.icon.uri}
                      alt={group.icon.alt_text}
                      className="icon"
                    />
                  </div>
                </Link>
                <ul className="home--links-group-nav">
                  {group.items.map((item, itemIndex) => {
                    return (
                      <li
                        className="home--links-group-nav-item"
                        key={itemIndex}
                      >
                        <Link
                          to={item.url}
                          className="home--links-group-nav-item-link"
                        >
                          <h3 className="home--links-group-nav-item-label">
                            {item.label}
                          </h3>
                          <p className="home--links-group-nav-item-description">
                            {item.description}
                          </p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </section>
      <section className="home--map">
        <div className="wrapper">
          <h2 className="home--map-title">{page.map.title}</h2>
          <p className="home--map-description">{page.map.description}</p>
          <Link
            to={page.map.link_url}
            className="btn btn_block darkBg home--map-cta"
          >
            <span>{page.map.link_label}</span>
          </Link>
          <Image
            src={page.map.image.uri}
            alt={page.map.image.alt_text}
            minWidth={1600}
            className="home--map-img"
          />
        </div>
      </section>
    </Layout>
  );
};

export default HomeView;
