import { MediaPostViewContext, MediaPostViewProps } from '../..';
import { BCMSParserFunction } from '../parser';

export const MediaPostParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const newPosts: Array<{
      title: string;
      slug: string;
    }> = cache.media.slice(0, 5).map((media) => {
      return {
        title: media.meta.title,
        slug: media.meta.slug,
      };
    });
    cache.media.forEach((media) => {
      const props: MediaPostViewProps = {
        page: {
          title: media.meta.title,
          subtitle: media.meta.subtitle,
          description: media.meta.description,
          date: media.meta.date,
        },
        content: media.content,
        newPosts,
      };
      const context: MediaPostViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
        newPosts: JSON.stringify(props.newPosts),
      };
      createPage({
        path: `/media/${media.meta.slug}`,
        component,
        context,
      });
    });
  },
);
