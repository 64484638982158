import {
  OurInsightsReportsViewContext,
  OurInsightsReportsViewProps,
} from '../..';
import { BCMSParserFunction } from '../parser';

export const OurInsightsReportsParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const props: OurInsightsReportsViewProps = {
      posts: [
        ...cache.whitepapers.map((whitepaper) => {
          return {
            type: 'whitepapers',
            cover_image: whitepaper.meta.cover_image,
            cover_image_alt_text: whitepaper.meta.cover_image_alt_text,
            description: whitepaper.meta.description,
            slug: whitepaper.meta.slug,
            title: whitepaper.meta.title,
            date: whitepaper.meta.date,
          };
        }),
        ...cache.briefings.map((briefing) => {
          return {
            type: 'briefings',
            cover_image: briefing.meta.cover_image,
            cover_image_alt_text: briefing.meta.cover_image_alt_text,
            description: briefing.meta.description,
            slug: briefing.meta.slug,
            title: briefing.meta.title,
            date: briefing.meta.date,
          };
        }),
      ].sort((a, b) => {
        return b.date - a.date;
      }),
    };

    const context: OurInsightsReportsViewContext = {
      posts: JSON.stringify(props.posts),
    };

    createPage({
      path: '/reports',
      component,
      context,
    });
  },
);
