import { ContactUsViewProps, ContactUsViewContext } from './../../types';
import { BCMSParserFunction } from './parser';

export const ContactUsParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.info_pages.contactUs.forEach((page) => {
      const props: ContactUsViewProps = {
        page: {
          title: page.meta.title,
          cover_image: page.meta.cover_image,
          cover_image_alt_text: page.meta.cover_image_alt_text,
          subtitle: page.meta.subtitle,
          description: page.meta.description,
        },
        content: page.content,
      };
      const context: ContactUsViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        component,
        path: `/contact-us`,
        context,
      });
    });
  },
);
