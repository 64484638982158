import React from 'react';
import { Article, Breadcrumb, Layout } from '../../components';
import { PathService } from '../../services';
import { OurInsightsReportViewProps } from '../../types';

const OurInsightsReportView: React.FunctionComponent<OurInsightsReportViewProps> = ({
  content,
  page,
  relatedPosts,
}) => {
  return (
    <Layout
      title={page.title}
      description={page.description}
      banner={{
        src: page.cover_image
          ? page.cover_image
          : '/media/our-insights/whitepapers/whitepapers-banner.jpg',
        srcAlt: page.cover_image_alt_text
          ? page.cover_image_alt_text
          : page.title,
        subtitle: page.subtitle,
        title: page.title,
      }}
      className="articleWithPatternLayout"
    >
      <div className="overview whitepaper">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Reports',
                path: '/reports',
              },
              {
                label: page.title,
                path: PathService.get(),
              },
            ]}
          />
          <Article
            content={content}
            date={page.date}
            tags={page.tags}
            related={{
              title: 'Related Reports',
              items: relatedPosts,
              slug_prefix: '/reports',
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default OurInsightsReportView;
