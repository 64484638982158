import { OurInsightsBlogsContext, OurInsightsBlogsProps } from '../..';
import { BCMSParserFunction } from '../parser';

export const OurInsightsBlogsParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const props: OurInsightsBlogsProps = {
      posts: cache.blogs
        .map((blog) => {
          return {
            type: 'blog',
            title: blog.meta.title,
            description: blog.meta.description,
            cover_image: blog.meta.cover_image,
            cover_image_alt_text: blog.meta.cover_image_alt_text,
            slug: blog.meta.slug,
            unindexed_and_unreachable: blog.meta.unindexed_and_unreachable,
          };
        })
        .filter((e) => !e.unindexed_and_unreachable),
    };
    const context: OurInsightsBlogsContext = {
      posts: JSON.stringify(props.posts),
    };
    createPage({
      path: '/our-insights/blog',
      component,
      context,
    });
  },
);
