import { VideoViewContext, VideoViewProps } from '../../../types';
import { BCMSParserFunction } from '../parser';

export const VideoParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.video.forEach((video) => {
      const props: VideoViewProps = {
        video: video.meta,
      };
      const context: VideoViewContext = {
        video: JSON.stringify(props.video),
      };
      createPage({
        path: `/our-insights/video/${video.meta.slug}`,
        component,
        context,
      });
    });
  },
);
