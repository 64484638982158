import { BCMSParserFunction } from '../parser';
import { OurStoryViewProps, OurStoryViewContext } from '../../../types';

export const OurStoryPostParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.info_pages.ourStory.sort((a, b) => a.createdAt - b.createdAt);
    cache.info_pages.ourStory.forEach((page) => {
      const props: OurStoryViewProps = {
        content: page.content,
        sideNav: cache.info_pages.ourStory.map((e) => {
          return {
            slug:
              e.meta.internal_slug === 'overview' ? '' : e.meta.internal_slug,
            title: e.meta.internal_name,
            subNavItems: [],
          };
        }),
        page: {
          subtitle: page.meta.subtitle,
          internal_slug: page.meta.internal_slug.replace('overview', ''),
          title: page.meta.title,
          cover_image: page.meta.cover_image,
          cover_image_alt_text: page.meta.cover_image_alt_text,
        },
      };
      const context: OurStoryViewContext = {
        content: JSON.stringify(props.content),
        page: JSON.stringify(props.page),
        sideNav: JSON.stringify(props.sideNav),
      };
      createPage({
        component,
        path: `/our-story${
          page.meta.internal_slug === 'overview'
            ? ''
            : '/' + page.meta.internal_slug
        }`,
        context,
      });
    });
  },
);
