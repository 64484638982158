import React from 'react';
import { Article, LanguageSwitch, Layout } from '../components';
import AsideRichTextWidget from '../components/widgets/aside-rich-text';
import { PinkertonLocationsViewProps } from '../types';

const PinkertonLocationsView: React.FunctionComponent<PinkertonLocationsViewProps> = ({
  page,
  content,
  languageSwitch,
}) => {
  return (
    <Layout
      title={page.title}
      description={page.description}
      banner={{
        src: page.cover_image.uri,
        srcAlt: page.cover_image.alt_text,
        subtitle: page.subtitle,
        title: page.title,
      }}
      className="articleWithAsideLayout"
    >
      <div className="wrapper">
        <LanguageSwitch items={languageSwitch.items} />
      </div>
      <div className="overview locations">
        <div className="wrapper">
          <div className="twoColSingle">
            <Article
              content={content.filter((e) => e.name !== 'aside_rich_text')}
            />
            {content.find((e) => e.name === 'aside_rich_text')?.value && (
              <AsideRichTextWidget
                className="twoColSingle--aside"
                content={
                  (content.find((e) => e.name === 'aside_rich_text')
                    ?.value as any)?.content
                }
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PinkertonLocationsView;
