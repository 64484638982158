import { BCMSParserFunction } from './parser';

export const P404Parser = BCMSParserFunction(
  async (createPage, component, cache) => {
    createPage({
      component,
      path: '/404',
      context: {
        rewrites: cache.rewrites,
      },
    });
  },
);
