import { ImageGroup } from '../../types';

export interface RelatedItemInput {
  _id: string;
  meta: {
    title: string;
    description: string;
    slug: string;
    tags: string[];
    cover_image: string;
    cover_image_alt_text: string;
    unindexed_and_unreachable?: boolean;
  };
}
export interface RelatedItemOutput {
  _id: string;
  slug: string;
  title: string;
  description: string;
  image: ImageGroup;
}

export interface GeneralUtilPrototype {
  getRelated(
    items: RelatedItemInput[],
    target: RelatedItemInput,
    limit: number,
  ): RelatedItemOutput[];
  string: {
    removeSame(arr: string[]): string[];
    createSlug(e: string): string;
  };
}

function generalUtil(): GeneralUtilPrototype {
  return {
    string: {
      removeSame(arr) {
        const output: string[] = [];
        for (let i = 0; i < arr.length; i = i + 1) {
          if (!output.includes(arr[i])) {
            output.push(arr[i]);
          }
        }
        return output;
      },
      createSlug(e: string) {
        if (typeof e !== 'string') {
          throw new Error(`'e' is not a "string".`);
        }
        return e
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/_/g, '-')
          .replace(/[^a-z0-9 - ---]/g, '');
      },
    },
    getRelated(items, target, limit) {
      const output: RelatedItemOutput[] = [];
      items = items.filter((e) => !e.meta.unindexed_and_unreachable);
      for (const i in items) {
        const item = items[i];
        if (output.length < limit) {
          if (
            item._id !== target._id &&
            item.meta.tags.find((tag) =>
              target.meta.tags.find((targetTag) => targetTag === tag),
            )
          ) {
            output.push({
              _id: item._id,
              title: item.meta.title,
              slug: `/${item.meta.slug}`,
              description: item.meta.description,
              image: {
                uri: item.meta.cover_image,
                alt_text: item.meta.cover_image_alt_text,
              },
            });
          }
        } else {
          break;
        }
      }
      return output;
    },
  };
}

export const GeneralUtil = generalUtil();
