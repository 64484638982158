import React from 'react';
import { Helmet } from 'react-helmet';
import { Article, Breadcrumb, Layout } from '../../components';
import { PathService } from '../../services';
import { OurInsightsBlogViewProps } from '../../types';

const OurInsightsBlogView: React.FunctionComponent<
  OurInsightsBlogViewProps
> = ({ content, page, relatedPosts }) => {
  return (
    <Layout
      className="blogSingle articleWithPatternLayout"
      title={page.title}
      description={page.description}
      banner={{
        src: page.cover_image
          ? page.cover_image
          : '/media/our-insights/blogs/blog-banner.jpg',
        srcAlt: page.cover_image_alt_text
          ? page.cover_image_alt_text
          : page.title,
        subtitle: page.subtitle,
        title: page.title,
      }}
    >
      {page.unindexed_and_unreachable && (
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      )}
      <div className="overview blog">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Our insights',
                path: '/our-insights',
              },
              {
                label: 'Blog',
                path: '/our-insights/blog',
              },
              {
                label: page.title,
                path: PathService.get(),
              },
            ]}
          />
          <Article
            date={page.date}
            content={content}
            tags={page.tags}
            related={{
              title: 'Related Blogs',
              items: relatedPosts,
              slug_prefix: '/our-insights/blog',
            }}
            formId="dc14653c-7e92-448f-9423-b3c115abbe9e"
          />
        </div>
      </div>
    </Layout>
  );
};

export default OurInsightsBlogView;
