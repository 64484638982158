import {
  CareerOpeningViewContext,
  CareerOpeningViewProps,
} from '../../../types';
import { BCMSParserFunction } from '../parser';

export const CareerOpeningParser = BCMSParserFunction(
  async (createPage, component, cache, functionCache) => {
    functionCache.pinkerton_hr.jobs.forEach((job) => {
      const props: CareerOpeningViewProps = {
        job,
      };
      const context: CareerOpeningViewContext = {
        job: JSON.stringify(props.job),
      };
      createPage({
        path: `/job/${job.referencenumber}/${job.title
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/_/g, '-')
          .replace(/--/g, '-')
          .replace(/[^a-z0-9 - ---]/g, '')}`,
        component,
        context,
      });
    });
  },
);
