import { EventPostViewContext, EventPostViewProps } from '../..';
import { BCMSParserFunction } from '../parser';

export const EventPostParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.events.forEach((event) => {
      const props: EventPostViewProps = {
        page: {
          title: event.meta.title,
          subtitle: event.meta.subtitle,
          description: event.meta.description,
          cover_image: event.meta.cover_image,
          cover_image_alt_text: event.meta.cover_image_alt_text,
        },
        content: event.content,
      };
      const context: EventPostViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        path: `/${event.meta.slug}`,
        component,
        context,
      });
    });
  },
);
