import { OnDemandWebinarViewContext, OnDemandWebinarViewProps } from '..';
import { BCMSParserFunction } from './parser';

export const OnDemandWebinarParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.on_demand_webinar.forEach((entry) => {
      const props: OnDemandWebinarViewProps = {
        page: {
          title: entry.meta.title,
          subtitle: entry.meta.subtitle,
          slug: entry.meta.slug,
          cover_image: entry.meta.cover_image,
          aside_hubspot: entry.meta.aside_hubspot,
        },
        content: entry.content,
      };
      const context: OnDemandWebinarViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        path: entry.meta.slug,
        component,
        context,
      });
    });
  },
);
