import {
  OurInsightsRiskSnapshotViewContext,
  OurInsightsRiskSnapshotViewProps,
} from '../../../types';
import { GeneralUtil } from '../../util';
import { BCMSParserFunction } from '../parser';

export const OurInsightsRiskSnapshotParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.risk_snapshots.forEach((snapshot) => {
      const props: OurInsightsRiskSnapshotViewProps = {
        page: {
          title: snapshot.meta.title,
          subtitle: snapshot.meta.subtitle,
          cover_image: snapshot.meta.cover_image,
          cover_image_alt_text: snapshot.meta.cover_image_alt_text,
          description: snapshot.meta.description,
          date: snapshot.meta.date,
          tags: snapshot.meta.tags,
        },
        content: snapshot.content,
        relatedPosts: GeneralUtil.getRelated(cache.risk_snapshots, snapshot, 5),
      };
      const context: OurInsightsRiskSnapshotViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
        relatedPosts: JSON.stringify(props.relatedPosts),
      };
      createPage({
        path: `/our-insights/risk-snapshots/${snapshot.meta.slug}`,
        component,
        context,
      });
    });
  },
);
