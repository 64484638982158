import React from 'react';
import { GAMViewProps, PropType } from './../types';
import {
  Article,
  Layout,
  StickyButtonsWidget,
  WidgetManager,
} from './../components';

const GAMView: React.FunctionComponent<GAMViewProps> = ({ page, content }) => {
  const widgetsToFilter = ['double_banners', 'cta_large', 'reference'];

  const filteredContent = content.filter(
    (e) => !widgetsToFilter.includes(e.name),
  );

  const largeWidgets = content.filter((e) => widgetsToFilter.includes(e.name));

  return (
    <Layout
      title={page.title}
      banner={{
        src: page.cover_image.uri,
        srcAlt: page.cover_image.alt_text,
        subtitle: page.subtitle,
        title: page.title,
      }}
      className="articleWithAsideLayout"
    >
      <div className="overview gam">
        <div className="wrapper">
          <div className="twoColSingle">
            <Article content={filteredContent} />
            <aside className="twoColSingle--aside">
              {page.aside.title && (
                <h3 className="aside--title">{page.aside.title}</h3>
              )}
              <div
                className="aside--content"
                dangerouslySetInnerHTML={{ __html: page.aside.content }}
              />
              <footer className="aside--footer">
                <div dangerouslySetInnerHTML={{ __html: page.aside_footer }} />
              </footer>
            </aside>
          </div>
        </div>
        {largeWidgets.map((contentItem, contentItemIndex) => {
          switch (contentItem.type) {
            case PropType.WIDGET: {
              return (
                <WidgetManager
                  key={'' + contentItemIndex}
                  widget={contentItem}
                ></WidgetManager>
              );
            }
          }
        })}
        {page.mobile_cta && (
          <StickyButtonsWidget
            buttons={[
              {
                label: page.mobile_cta.label,
                url: page.mobile_cta.link_to,
                theme: {
                  items: [],
                  selected: 'TRANSPARENT',
                },
              },
            ]}
          />
        )}
      </div>
    </Layout>
  );
};

export default GAMView;
