import {
  FindARiskAdvisorOverviewViewContext,
  FindARiskAdvisorOverviewViewProps,
  FindARiskAdvisorOverviewViewPropsRegion,
  FindARiskAdvisorOverviewViewPropsRegionDirector,
} from '../..';
import { EntryContentParsedItem, PropType } from '../../../types';
import { BCMSParserFunction } from '../parser';

export const FindARiskAdvisorOverviewParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const regions: {
      [name: string]: FindARiskAdvisorOverviewViewPropsRegion;
    } = {};
    cache.regions.forEach((region) => {
      const regionName =
        region.meta.web.selected && region.meta.web.selected !== ''
          ? region.meta.web.selected
          : '';
      if (!regions[regionName]) {
        if (regionName === '') {
          return;
        }
        regions[regionName] = {
          name: regionName.replace(/_/g, ' '),
          directors: [],
        };
      }
      regions[regionName].directors = [
        ...regions[regionName].directors,
        ...region.meta.directors
          .filter((e) => e.director && e.director.meta)
          .map((director) => {
            const dire: FindARiskAdvisorOverviewViewPropsRegionDirector = {
              title: director.director.meta.en.title,
              slug: director.director.meta.en.slug,
              position: director.director.meta.en.position,
              region: director.director.meta.en.region.selected,
              img: director.director.meta.en.image_uri,
            };
            return dire;
          }),
        ...cache.directors
          .filter((e) => e.meta['en'].region.selected === regionName)
          .map((e) => {
            const dire: FindARiskAdvisorOverviewViewPropsRegionDirector = {
              title: e.meta['en'].title,
              slug: e.meta['en'].slug,
              position: e.meta['en'].position,
              region: e.meta['en'].region.selected,
              img: e.meta['en'].image_uri,
            };
            return dire;
          }),
      ]
        .filter((v, i, a) => a.findIndex((t) => t.slug === v.slug) === i)
        .filter((e) => e.region !== 'NONE' && e.region);
    });
    const props: FindARiskAdvisorOverviewViewProps = {
      regions: Object.keys(regions)
        .filter((e) => e !== 'NONE')
        .map((key) => {
          regions[key].directors.sort((a, b) => {
            if (a.title < b.title) {
              return -1;
            } else if (a.title > b.title) {
              return 1;
            }
            return 0;
          });
          return regions[key];
        })
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
      directorsWithRegions: cache.regions
        .filter((region) => region.meta.directors[0]?.director.meta)
        .map((region) => {
          const output = JSON.parse(JSON.stringify(region.meta.directors[0]));
          output.director.meta = output.director.meta.en;
          output.director.content = output.director.content.en.find(
            (e: EntryContentParsedItem) => e.type === PropType.PARAGRAPH,
          )?.value;
          output.regions = [...output.sub_regions].map((subRegion) => {
            subRegion.content = [];
            subRegion.meta.title = subRegion.meta.title.replace(/\n/g, '');
            return subRegion;
          });
          output.sub_regions = undefined;
          return output;
        }),
    };
    const context: FindARiskAdvisorOverviewViewContext = {
      regions: JSON.stringify(props.regions),
      directorsWithRegions: JSON.stringify(props.directorsWithRegions),
    };
    createPage({
      path: '/find-your-pinkerton',
      component,
      context,
    });
  },
);
