import * as uuid from 'uuid';
import React from 'react';
import { Layout, PostList } from '../../components';
import { OurInsightsAnalysisViewProps } from '../../types';

const OurInsightsAnalysisView: React.FunctionComponent<
  OurInsightsAnalysisViewProps
> = ({ briefings, perspectivesInCrime, infographics }) => {
  return (
    <Layout
      title="Our Insights"
      description="Our insights, including infographics, blog, whitepapers and analysis on current risk trends."
      banner={{
        src: '/media/our-insights/analysis/analysis-banner.jpg',
        subtitle: 'Get insights that help you navigate the risk landscape',
        title: 'Briefings',
      }}
      className="sectionsLayout"
    >
      <div className="overview our-insights">
        <div className="wrapper">
          <header className="overview--header">
            <p className="overview--section-description">
              We strive to help our clients make informed decisions. Whether
              you're seeking underlying determinents of risk or looking for
              patterns of causality, we'll help you connect dots in a way you
              haven't seen before. We provide analysis guided by technology and
              machine learning. Our in-depth data uses a total risk perspective,
              to enable you to be proactive at helping your organization plan
              for the future.
            </p>
          </header>

          <section className="overview--section">
            <h3 className="overview--section-title">Infographics</h3>
            <PostList
              list={infographics.map((e) => {
                return {
                  uuid: uuid.v4(),
                  ...e,
                };
              })}
              prefix=""
            />
          </section>

          <section className="overview--section">
            <h3 className="overview--section-title">Perspectives in Crime</h3>
            <PostList
              list={perspectivesInCrime.map((e) => {
                return {
                  uuid: uuid.v4(),
                  ...e,
                };
              })}
              prefix=""
            />
          </section>

          <section className="overview--section">
            <h3 className="overview--section-title">Briefings</h3>
            <PostList
              list={briefings.map((e) => {
                return {
                  uuid: uuid.v4(),
                  ...e,
                };
              })}
              prefix=""
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default OurInsightsAnalysisView;
