import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../components';
import { TagsViewProps } from '../types';

const TagsView: React.FunctionComponent<TagsViewProps> = ({ title, items }) => {
  return (
    <Layout
      title={title || 'Tags'}
      description=""
      OGImageUri="/media/home/hero.jpg"
    >
      <div className="overview search moveFromHeader">
        <div className="wrapper">
          <h1 className="search--title">{title ? `${title} results` : 'Results'}</h1>
          <ul className="search--results">
            {items.length ? (
              items.map((item, itemIndex) => {
                return (
                  <li className="search--result" key={itemIndex}>
                    <Link to={item.uri}>
                      <p className="search--result-category">{item.category}</p>
                      <div>
                        <div className="search--result-text">
                          <h2 className="search--result-title">{item.title}</h2>
                          <p
                            className="search--result-description"
                            dangerouslySetInnerHTML={{
                              __html: item.description
                                ? item.description
                                : 'No description provided',
                            }}
                          ></p>
                        </div>
                        <svg
                          className="svg-inline--fa fa-long-arrow-right fa-w-14"
                          aria-hidden="true"
                          data-prefix="fal"
                          data-icon="long-arrow-right"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"
                          />
                        </svg>
                      </div>
                    </Link>
                  </li>
                );
              })
            ) : (
              <p className="search--description">No matches found</p>
            )}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default TagsView;
