import { ISOLangs } from '../../data';
import {
  PinkertonLocationsViewContext,
  PinkertonLocationsViewProps,
} from '../../types';
import { BCMSParserFunction } from './parser';

export const PinkertonLocationsParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.pinkerton_locations.forEach((entry) => {
      const langs = Object.keys(entry.meta)
        .map((e) => e)
        .filter((e) => entry.meta[e].title);
      const languageSwitch: Array<{ label: string; path: string }> = langs.map(
        (lang) => {
          return {
            label: ISOLangs[lang || 'en'].nativeName,
            path: `/${entry.meta[lang].slug}${lang !== 'en' ? '/' + lang : ''}`,
          };
        },
      );
      langs.forEach((lang) => {
        const props: PinkertonLocationsViewProps = {
          page: {
            title: entry.meta[lang].title,
            subtitle: entry.meta[lang].subtitle,
            cover_image: entry.meta[lang].cover_image,
            description: entry.meta[lang].description,
          },
          content: entry.content[lang],
          languageSwitch: {
            items: languageSwitch,
          },
        };
        const context: PinkertonLocationsViewContext = {
          page: JSON.stringify(props.page),
          content: JSON.stringify(props.content),
          languageSwitch: JSON.stringify(props.languageSwitch),
        };
        const isEn = lang === 'en';
        createPage({
          path: `/${entry.meta[lang].slug}${!isEn ? '/' + lang : ''}`,
          component,
          context,
        });
      });
    });
  },
);
