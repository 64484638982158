import { BCMSCache, BCMSFunctionCache, GatsbyCreatePage } from '..';

export function BCMSParserFunction(
  handler: (
    createPage: GatsbyCreatePage,
    component: string,
    cache: BCMSCache,
    functionCache: BCMSFunctionCache,
  ) => Promise<void>,
): (
  createPage: GatsbyCreatePage,
  component: string,
  cache: BCMSCache,
  functionCache: BCMSFunctionCache,
) => Promise<void> {
  return handler;
}
