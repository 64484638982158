import { OurApproachViewContext, OurApproachViewProps } from '..';
import { BCMSParserFunction } from './parser';

export const OurApproachParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.our_approach.forEach((solution) => {
      const props: OurApproachViewProps = {
        page: {
          title: solution.meta.title,
          subtitle: solution.meta.subtitle,
          cover_image: solution.meta.cover_image,
          description: solution.meta.description,
          tags: solution.meta.tags,
          aside: solution.meta.aside,
        },
        content: solution.content,
      };
      const context: OurApproachViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        path: solution.meta.slug,
        component,
        context,
      });
    });
  },
);
