import { Link } from 'gatsby';
import React from 'react';
import * as uuid from 'uuid';
import { AccordionWidget, Layout, PostList } from '../../components';
import { GeneralService, PathService } from '../../services';
import { PressRoomOverviewProps } from '../../types';

const PressRoomOverviewView: React.FunctionComponent<PressRoomOverviewProps> = ({
  inTheNews,
  recentEntries,
  pressArchive,
}) => {
  return (
    <Layout
      title="Press Room"
      description={`Learn about Pinkerton in the news.`}
      banner={{
        src: '/media/press-and-events/badge-banner.jpg',
        subtitle: 'Pinkerton in the Press',
        title: 'Press Room',
      }}
      className="articleWithAsideLayout"
    >
      <div className="overview">
        <div className="wrapper">
          <header className="overview--header">
            <h2 className="overview--header-description">
              As a leading global provider of comprehensive risk management
              services, Pinkerton leaders and agents have been featured in
              publications all around the world.
            </h2>
          </header>

          <hr />
          <br />

          <section className="overview--section">
            <p className="overview--section-description">
              For media inquiries, please email us at{' '}
              <a href="mailto:media.pinkerton@pinkerton.com">
                media.pinkerton@pinkerton.com
              </a>
              .
            </p>
          </section>

          <section className="overview--section">
            <h3 className="overview--section-title">In the News</h3>
            <p className="overview--section-description">
              Bylines and publications featuring Pinkerton thought leadership.
            </p>
            <PostList
              list={inTheNews.map((e) => {
                return {
                  uuid: uuid.v4(),
                  ...e,
                };
              })}
              chunkSize={6}
              noTransform={true}
            />
          </section>

          <section className="overview--section">
            <h3 className="overview--section-title">Press Releases</h3>
            <PostList
              list={recentEntries.map((e) => {
                return {
                  uuid: uuid.v4(),
                  ...e,
                };
              })}
              noTransform={true}
              prefix={PathService.get()}
            />
          </section>
        </div>
        <div className="articleWithPatternLayout">
          <div className="overview">
            <div className="wrapper">
              <div className="twoColSingle">
                <section className="overview--section twoColSingle--main">
                  {pressArchive.length > 0 && (
                    <h3 className="overview--section-title">Press Archive</h3>
                  )}
                  {pressArchive.length > 0 &&
                    pressArchive.map((pressArchiveItem) => {
                      return (
                        <AccordionWidget
                          items={[
                            { name: pressArchiveItem.year + '', content: '' },
                          ]}
                          key={uuid.v4()}
                        >
                          <ul>
                            {pressArchiveItem.press.map((press) => {
                              const date = GeneralService.millis.toDateStringArray(
                                press.date,
                              );
                              return (
                                <li key={uuid.v4()}>
                                  <Link to={`/press-room/${press.slug}`}>
                                    {date[1]} {date[2]} - {press.title}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </AccordionWidget>
                      );
                    })}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PressRoomOverviewView;
