import React from 'react';
import { Layout } from '../components';

const EmploymentScreeningView: React.FunctionComponent = () => {
  return (
    <Layout
      title="Employment Screening"
      description="Access our employment screening portal."
      className="articleWithPatternLayout"
    >
      <div className="overview employment-screening">
        <div className="wrapper">
          <div className="twoColSingle">
            <section className="twoColSingle--main overview--section">
              <h1 className="overview--section-title">
                Employment Screening Client Portal
              </h1>
              <p className="overview--section-description">
                Begin by clicking the button below to access the client system.
                It is suggested for your convenience to please bookmark your
                direct link for future use.
              </p>
              <a
                href="https://pinkerton.clearstar.net/csge/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn_block"
              >
                <span>U.S. Portal</span>
              </a>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmploymentScreeningView;
