import {
  OurInsightsReportViewContext,
  OurInsightsReportViewProps,
} from '../..';
import { GeneralUtil } from '../../util';
import { BCMSParserFunction } from '../parser';

export const OurInsightsReportParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.whitepapers.forEach((whitepaper) => {
      const props: OurInsightsReportViewProps = {
        page: {
          title: whitepaper.meta.title,
          subtitle: whitepaper.meta.subtitle,
          description: whitepaper.meta.description,
          cover_image: whitepaper.meta.cover_image,
          cover_image_alt_text: whitepaper.meta.cover_image_alt_text,
          date: whitepaper.meta.date,
          tags: whitepaper.meta.tags,
        },
        content: whitepaper.content,
        relatedPosts: GeneralUtil.getRelated(cache.whitepapers, whitepaper, 5),
      };

      const context: OurInsightsReportViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
        relatedPosts: JSON.stringify(props.relatedPosts),
      };

      createPage({
        path: `/reports/${whitepaper.meta.slug}`,
        component,
        context,
      });
    });

    cache.briefings.forEach((briefing) => {
      const props: OurInsightsReportViewProps = {
        page: {
          title: briefing.meta.title,
          subtitle: briefing.meta.subtitle,
          description: briefing.meta.description,
          cover_image: briefing.meta.cover_image,
          cover_image_alt_text: briefing.meta.cover_image_alt_text,
          date: briefing.meta.date,
          tags: briefing.meta.tags,
        },
        content: briefing.content,
        relatedPosts: GeneralUtil.getRelated(cache.whitepapers, briefing, 5),
      };

      const context: OurInsightsReportViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
        relatedPosts: JSON.stringify(props.relatedPosts),
      };

      createPage({
        path: `/reports/${briefing.meta.slug}`,
        component,
        context,
      });
    });
  },
);
