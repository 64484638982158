import React from 'react';
import { Breadcrumb, Layout, VimeoCard } from '../../components';
import { VideoViewProps } from '../../types';

const OurInsightsVideoView: React.FunctionComponent<VideoViewProps> = ({
  video,
}) => {
  return (
    <Layout
      title={video.title}
      banner={{
        src: video.cover_image.uri,
        srcAlt: video.cover_image.alt_text,
        subtitle: 'Pinkerton video insights',
        title: video.title,
      }}
    >
      <div className="overview videos">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Our insights',
                path: '/our-insights',
              },
              {
                label: 'Video',
                path: '/our-insights/video',
              },
            ]}
          />
          <div className="videos--note">
            Click the share icon to copy the video link.
          </div>
          <VimeoCard video={video} activeTag={'video'} />
        </div>
      </div>
    </Layout>
  );
};

export default OurInsightsVideoView;
