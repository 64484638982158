import React from 'react';
import {
  AccordionWidget,
  Breadcrumb,
  Layout,
  VimeoCard,
} from '../../components';
import { VideosViewProps, VideoMeta } from '../../types';

const OurInsightsVideosView: React.FunctionComponent<VideosViewProps> = ({
  videos,
}) => {
  const [activeTag, setActiveTag] = React.useState('');
  const [tags, setTags] = React.useState<string[]>([]);
  const [filteredItems, setFilteredItems] = React.useState<VideoMeta[]>([]);

  React.useEffect(() => {
    setTags(
      Array.from(
        new Set(videos.map((video) => video.tags).flat()),
      ).sort((a, b) => a.localeCompare(b)),
    );
    setFilteredItems(videos);
  }, []);

  React.useEffect(() => {
    if (activeTag === '') {
      setFilteredItems(videos);
    } else {
      setFilteredItems(
        videos.filter((video) => video.tags.includes(activeTag)),
      );
    }
  }, [activeTag]);

  return (
    <Layout
      title="Video"
      banner={{
        src: '/media/videos/video-banner.jpg',
        srcAlt: 'Videos',
        subtitle: 'Pinkerton video insights',
        title: 'Videos',
      }}
    >
      <div className="overview videos">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Our insights',
                path: '/our-insights',
              },
              {
                label: 'Video',
                path: '/our-insights/video',
              },
            ]}
          />
          <AccordionWidget
            className="videos--tags"
            items={[
              {
                name: `Filter by${activeTag ? ' - ' + activeTag : ''}`,
                content: '',
              },
            ]}
          >
            {tags.map((tag, index) => {
              return (
                <li
                  key={index}
                  className={`videos--tags-item ${
                    activeTag === tag ? 'videos--tags-item_active' : ''
                  }`}
                >
                  <button
                    className="videos--tags-item-btn"
                    onClick={() => {
                      setActiveTag(tag);
                    }}
                  >
                    <span>{tag}</span>
                  </button>
                </li>
              );
            })}
            <li
              className={`videos--tags-item ${
                activeTag === '' ? 'videos--tags-item_active' : ''
              }`}
            >
              <button
                className="videos--tags-item-btn"
                onClick={() => {
                  setActiveTag('');
                }}
              >
                <span>View all</span>
              </button>
            </li>
          </AccordionWidget>
          <div className="videos--note">
            Click the share icon to copy the video link.
          </div>
          <ul className="videos--list">
            {filteredItems.map((video) => {
              return (
                <li key={video.video_url} className="videos--list-item">
                  <VimeoCard video={video} activeTag={activeTag} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default OurInsightsVideosView;
