import * as uuid from 'uuid';
import React from 'react';
import { Breadcrumb, Layout, PostList } from '../../components';
import { OurInsightsRiskSnapshotsProps } from '../../types';
import { PathService } from '../../services';

const OurInsightsRiskSnapshotsView: React.FunctionComponent<
  OurInsightsRiskSnapshotsProps
> = ({ posts }) => {
  return (
    <Layout
      title="Risk Snapshots"
      banner={{
        src: '/media/our-insights/pcirs-lp.jpg',
        subtitle:
          'Quick view of Pinkerton Crime Index Scores for points of interest around the world',
        title: 'PCI Risk Snapshots',
      }}
      className="sectionsLayout"
    >
      <div className="overview blogs">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Our insights',
                path: '/our-insights',
              },
              {
                label: 'Risk Snapshots',
                path: '/our-insights/risk-snapshots',
              },
            ]}
          />
          <PostList
            list={posts.map((e) => {
              return {
                uuid: uuid.v4(),
                ...e,
              };
            })}
            prefix={PathService.get()}
          />
        </div>
      </div>
    </Layout>
  );
};

export default OurInsightsRiskSnapshotsView;
