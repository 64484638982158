import { TagItem, TagsViewContext } from '../../types';
import { GeneralUtil } from '../util';
import { BCMSParserFunction } from './parser';

export const TagsParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const items: { [tag: string]: TagItem[] } = {};

    cache.blogs.forEach((blog) => {
      blog.meta.tags.forEach((tag) => {
        const formattedTag = tag.toLowerCase().trim().replace(/ /g, '-');
        const blogItem = {
          category: 'Our insights - Blogs',
          title: blog.meta.title,
          description: blog.meta.description,
          uri: `/our-insights/blog/${blog.meta.slug}`,
        };
        if (!items[formattedTag]) {
          items[formattedTag] = [blogItem];
        } else {
          items[formattedTag].push(blogItem);
        }
      });
    });
    cache.briefings.forEach((briefing) => {
      briefing.meta.tags.forEach((tag) => {
        const formattedTag = tag.toLowerCase().trim().replace(/ /g, '-');
        const briefingItem = {
          category: 'Reports',
          title: briefing.meta.title,
          description: briefing.meta.description,
          uri: `/reports/${briefing.meta.slug}`,
        };
        if (!items[formattedTag]) {
          items[formattedTag] = [briefingItem];
        } else {
          items[formattedTag].push(briefingItem);
        }
      });
    });
    cache.whitepapers.forEach((whitepaper) => {
      whitepaper.meta.tags.forEach((tag) => {
        const formattedTag = tag.toLowerCase().trim().replace(/ /g, '-');
        const whitepaperItem = {
          category: 'Reports',
          title: whitepaper.meta.title,
          description: whitepaper.meta.description,
          uri: `/reports/${whitepaper.meta.slug}`,
        };
        if (!items[formattedTag]) {
          items[formattedTag] = [whitepaperItem];
        } else {
          items[formattedTag].push(whitepaperItem);
        }
      });
    });
    cache.media.forEach((media) => {
      media.meta.tags.forEach((tag) => {
        const formattedTag = tag.toLowerCase().trim().replace(/ /g, '-');
        const mediaItem = {
          category: 'Media',
          title: media.meta.title,
          description: media.meta.description,
          uri: `/media/${media.meta.slug}`,
        };
        if (!items[formattedTag]) {
          items[formattedTag] = [mediaItem];
        } else {
          items[formattedTag].push(mediaItem);
        }
      });
    });
    cache.risk_snapshots.forEach((snapshot) => {
      snapshot.meta.tags.forEach((tag) => {
        const formattedTag = tag.toLowerCase().trim().replace(/ /g, '-');
        const serviceItem = {
          category: 'Risk Snapshots',
          title: snapshot.meta.title,
          description: snapshot.meta.description,
          uri: `/our-insights/risk-snapshots/${snapshot.meta.slug}`,
        };
        if (!items[formattedTag]) {
          items[formattedTag] = [serviceItem];
        } else {
          items[formattedTag].push(serviceItem);
        }
      });
    });

    for (const [key, value] of Object.entries(items)) {
      const context: TagsViewContext = {
        title: key
          .split('-')
          .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
          .join(' '),
        items: JSON.stringify(value),
      };
      createPage({
        path: `/tags/${GeneralUtil.string.createSlug(key)}`,
        component,
        context,
      });
    }
  },
);
