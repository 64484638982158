import React from 'react';
import {
  Article,
  AsideWithIcons,
  Layout,
  TagsWidget,
  WidgetManager,
} from '../components';
import { OurApproachViewProps, PropType } from '../parser';

const OurApproachView: React.FunctionComponent<OurApproachViewProps> = ({
  page,
  content,
}) => {
  const widgetsToFilter = [
    'interested_in',
    'reference',
    'cta_large',
    'columns_with_icons',
    'content_with_visual',
    'tags',
  ];

  const filteredContent = content.filter(
    (e) => !widgetsToFilter.includes(e.name),
  );

  const largeWidgets = content.filter((e) => widgetsToFilter.includes(e.name));

  return (
    <Layout
      banner={{
        src: page.cover_image.uri,
        title: page.title,
        srcAlt: page.cover_image.alt_text,
        subtitle: page.subtitle,
      }}
      description={page.description}
      title={page.title}
      OGImageUri={page.cover_image.uri}
      className="articleWithAsideLayout"
    >
      <div className="overview ourApproach">
        <div className="wrapper">
          <div className="twoColSingle">
            <Article content={filteredContent} />
            <AsideWithIcons data={page.aside} />
          </div>
        </div>
        {largeWidgets.map((contentItem, contentItemIndex) => {
          switch (contentItem.type) {
            case PropType.WIDGET: {
              return (
                <WidgetManager
                  key={'' + contentItemIndex}
                  widget={contentItem}
                ></WidgetManager>
              );
            }
          }
        })}
        <div className="wrapper">
          {page.tags && page.tags.length > 0 && (
            <TagsWidget items={page.tags} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default OurApproachView;
