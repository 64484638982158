import {
  CareerOpeningsViewContext,
  CareerOpeningsViewProps,
  CareerOpeningsViewPropsJob,
} from '../../../types';
import { GeneralUtil } from '../../util';
import { BCMSParserFunction } from '../parser';

export const CareerOpeningsParser = BCMSParserFunction(
  async (createPage, component, _, functionCache) => {
    const jobCategories: string[] = GeneralUtil.string.removeSame(
      functionCache.pinkerton_hr.jobs.map((job) => {
        return job.category;
      }),
    );
    jobCategories.sort((a, b) => (a > b ? -1 : 1));
    const props: CareerOpeningsViewProps = {
      jobTypes: GeneralUtil.string.removeSame(
        functionCache.pinkerton_hr.jobs.map((job) => {
          return job.jobtype;
        }),
      ),
      jobCountries: GeneralUtil.string.removeSame(
        functionCache.pinkerton_hr.jobs
          .reduce<string[]>((acc, job) => {
            if (!acc.includes(job.country)) {
              acc.push(job.country);
            }

            const state = `${job.country} - ${job.state}`;
            if (
              !acc.includes(state) &&
              job.country.toLowerCase() === 'united states' &&
              job.state.toLowerCase() !== 'nationwide'
            ) {
              acc.push(state);
            }

            return acc;
          }, [])
          .sort((a, b) => (a > b ? 1 : -1)),
      ),
      jobs: jobCategories
        .map((category) => {
          const openings = functionCache.pinkerton_hr.jobs.filter(
            (opening) => opening.category === category,
          );
          const output: CareerOpeningsViewPropsJob = {
            category,
            openings: openings
              .map((e) => {
                return {
                  city: e.city,
                  country: e.country,
                  referenceNumber: e.referencenumber,
                  state: e.state,
                  title: e.title,
                  type: e.jobtype,
                  highPriority: e.HighPriority,
                };
              })
              .sort((e) => {
                return e.highPriority.toLowerCase() === 'yes' ? -1 : 1;
              }),
          };
          return output;
        })
        .sort((a, b) => {
          if (a.category > b.category) {
            return 1;
          } else {
            return -1;
          }
        }),
    };
    const context: CareerOpeningsViewContext = {
      jobTypes: props.jobTypes,
      jobCountries: props.jobCountries,
      jobs: JSON.stringify(props.jobs),
    };
    createPage({
      path: '/careers/current-openings',
      component,
      context,
    });
  },
);
