import { Link, navigate } from 'gatsby';
import React from 'react';
import { Layout } from '../components';
import { PathService } from '../services';
import { SearchItem, SearchViewProps } from '../types';

const SearchView: React.FunctionComponent<SearchViewProps> = ({ items }) => {
  const [hasSearchValue, setHasSearchValue] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState<SearchItem[]>([]);
  const searchInput = React.useRef<HTMLInputElement>(null);

  function search() {
    if (searchInput.current) {
      const searchValue = searchInput.current.value;
      filter(searchValue);
      navigate(`/search/?search=${encodeURIComponent(searchValue)}`, {
        replace: true,
      });
    }
  }
  function filter(_value: string) {
    setHasSearchValue(true);
    const value = _value.toLowerCase().trim().replace(/[-]/g, ' ');
    const byPriority: Array<SearchItem[]> = [[], [], []];
    items
      .filter((item) =>
        item.filterBy.toLowerCase().trim().replace(/[-]/g, ' ').includes(value),
      )
      .forEach((item) => {
        if (
          // TITLE - WHOLE WORD
          item.title
            .toLowerCase()
            .trim()
            .replace(/[-]/g, ' ')
            .match(new RegExp('\\b' + value + '\\b')) !== null
        ) {
          byPriority[0].push(item);
        } else if (
          // TITLE - CONTAINED
          item.title.toLowerCase().trim().replace(/[-]/g, ' ').includes(value)
        ) {
          byPriority[1].push(item);
        } else {
          // CATEGORY + DESCRIPTION - WHOLE WORD / DESCRIPTION
          byPriority[2].push(item);
        }
      });
    setFilteredItems(
      byPriority.reduce((previous, current) => {
        current.forEach((e) => {
          previous.push(e);
        });
        return previous;
      }, []),
    );
    if (searchInput.current) {
      searchInput.current.value = value;
    }
  }

  React.useEffect(() => {
    const queries: {
      search?: string;
    } = PathService.getQueries();
    if (queries.search) {
      filter(queries.search);
    }
  }, []);

  return (
    <Layout
      title="Search Results"
      description=""
      OGImageUri="/media/home/hero.jpg"
    >
      <div className="overview search moveFromHeader">
        <div className="wrapper">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              search();
            }}
            className="search--form"
          >
            <label>
              <span className="sr-only">Search Query:</span>
              <input
                type="text"
                name="search"
                placeholder="Search for an item..."
                title="Site Search"
                ref={searchInput}
              ></input>
            </label>
            <button type="submit" className="btn btn_block">
              <span className="sr-only">Search:</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </form>
          <h1 className="search--title">Results</h1>
          {!hasSearchValue ? (
            <p className="search--description">
              Please enter a word or phrase above
            </p>
          ) : (
            <ul className="search--results">
              {filteredItems.length ? (
                filteredItems.map((item, itemIndex) => {
                  return (
                    <li className="search--result" key={itemIndex}>
                      <Link to={item.uri}>
                        <p className="search--result-category">
                          {item.category}
                        </p>
                        <div>
                          <div className="search--result-text">
                            <h2 className="search--result-title">
                              {item.title}
                            </h2>
                            <p
                              className="search--result-description"
                              dangerouslySetInnerHTML={{
                                __html: item.description
                                  ? item.description
                                  : 'No description provided',
                              }}
                            ></p>
                          </div>
                          <svg
                            className="svg-inline--fa fa-long-arrow-right fa-w-14"
                            aria-hidden="true"
                            data-prefix="fal"
                            data-icon="long-arrow-right"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"
                            />
                          </svg>
                        </div>
                      </Link>
                    </li>
                  );
                })
              ) : (
                <p className="search--description">No matches found</p>
              )}
            </ul>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SearchView;
