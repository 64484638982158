import {
  ImageGroup,
  ServicesListViewContext,
  ServicesListViewProps,
} from '../..';
import { BCMSParserFunction } from '../parser';

export const ServicesListParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const groupedServices = cache.services.reduce<{
      [key: string]: Array<{
        title: string;
        slug: string;
        cover_image: ImageGroup;
        overview_text?: string;
      }>;
    }>((acc, e) => {
      if (acc[e.meta.page_group.selected as string]) {
        acc[e.meta.page_group.selected as string].push({
          cover_image: e.meta.cover_image,
          overview_text: e.meta.overview_text,
          slug: e.meta.slug,
          title: e.meta.title,
        });
      } else {
        acc[e.meta.page_group.selected as string] = [
          {
            cover_image: e.meta.cover_image,
            overview_text: e.meta.overview_text,
            slug: e.meta.slug,
            title: e.meta.title,
          },
        ];
      }
      return acc;
    }, {});

    Object.keys(groupedServices).forEach((group) => {
      const formattedGroup = group.toLowerCase().replace(/ /g, '-');
      const list = groupedServices[group];

      const props: ServicesListViewProps = {
        page_group: formattedGroup,
        list,
      };

      const context: ServicesListViewContext = {
        page_group: JSON.stringify(props.page_group),
        list: JSON.stringify(props.list),
      };

      createPage({
        path: `/${formattedGroup}`,
        component,
        context,
      });
    });
  },
);
