import { ServiceViewContext, ServiceViewProps } from '../..';
import { BCMSParserFunction } from '../parser';

export const ServicesParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.services.forEach((service) => {
      const formattedGroup = (service.meta.page_group.selected as string)
        .toLowerCase()
        .replace(/ /g, '-');

      const props: ServiceViewProps = {
        page: {
          title: service.meta.title,
          subtitle: service.meta.subtitle,
          page_group: formattedGroup,
          cover_image: service.meta.cover_image,
          description: service.meta.description,
          aside: service.meta.aside,
          introduction_text: service.meta.introduction_text,
        },
        content: service.content,
      };
      const context: ServiceViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        path: `/${formattedGroup}/${service.meta.slug}`,
        component,
        context,
      });
    });
  },
);
