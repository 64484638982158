import * as uuid from 'uuid';
import React from 'react';
import { Breadcrumb, Layout, PostList } from '../../components';
import { PathService } from '../../services';
import { OurInsightsReportsViewProps } from '../../parser';

const OurInsightsReportsView: React.FunctionComponent<
  OurInsightsReportsViewProps
> = ({ posts }) => {
  return (
    <Layout
      title="Reports"
      description="Pinkerton Reports."
      banner={{
        src: '/media/our-insights/whitepapers/whitepapers-banner.jpg',
        subtitle: 'In-depth guides into corporate risk',
        title: 'Reports',
      }}
      className="sectionsLayout"
    >
      <div className="overview whitepapers">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Reports',
                path: '/reports',
              },
            ]}
          />
          <PostList
            list={posts.map((e) => {
              return {
                uuid: uuid.v4(),
                ...e,
              };
            })}
            prefix={PathService.get().replace(/\/$/, '')}
          />
        </div>
      </div>
    </Layout>
  );
};

export default OurInsightsReportsView;
