import { LegalPostViewContext, LegalPostViewProps } from '../../../types';
import { BCMSParserFunction } from '../parser';

export const LegalPostParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.legal.forEach((post) => {
      const props: LegalPostViewProps = {
        page: {
          title: post.meta.title,
          subtitle: post.meta.subtitle,
          description: post.meta.description,
          cover_image: post.meta.cover_image,
          cover_image_alt_text: post.meta.cover_image_alt_text,
        },
        content: post.content,
      };
      const context: LegalPostViewContext = {
        page: JSON.stringify(props.page),
        content: JSON.stringify(props.content),
      };
      createPage({
        path: `/${post.meta.slug}`,
        component,
        context,
      });
    });
  },
);
