import { HomeViewContext, HomeViewProps } from '..';
import { BCMSParserFunction } from './parser';

export const HomeParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    cache.home.forEach((entry) => {
      const trending = entry.meta.featured_pages.find((p) => p.trending) as any;
      const spotlights = entry.meta.featured_pages.filter(
        (p) => !p.trending,
      ) as any;

      let trendingTitle = '';
      let trendingSlug = '/';
      let trendingDescription = '';
      let trendingImage = '';

      if (trending?.blog?.meta?.en) {
        const meta = trending.blog.meta.en;

        trendingTitle = meta.title;
        trendingSlug = `/our-insights/blog/${meta.slug}`;
        trendingDescription = meta.description || '';
        trendingImage = meta.cover_image || '';
      } else if (trending?.service?.meta?.en) {
        const meta = trending.service.meta.en;

        trendingTitle = meta.title;
        trendingSlug = meta.page_group?.selected
          ? `${meta.page_group.selected.toLowerCase()}/${meta.slug}`
          : '/';
        trendingDescription = meta.description || '';
        trendingImage = meta.cover_image?.uri || '';
      } else if (trending?.industry?.meta?.en) {
        const meta = trending.industry.meta.en;

        trendingTitle = meta.title;
        trendingSlug = `/industries/${meta.slug}`;
        trendingDescription = meta.hero?.description || '';
        trendingImage = meta.hero?.image?.uri || '';
      }

      if (!trendingImage) {
        trendingImage = entry.meta.hero?.image?.uri || '';
      }

      const props: HomeViewProps = {
        page: {
          title: entry.meta.title,
          slug: entry.meta.slug,
          hero: entry.meta.hero,
          trending:
            trending && trendingTitle
              ? {
                  title: trendingTitle,
                  slug: trendingSlug,
                  description: trendingDescription,
                  image: trendingImage,
                }
              : undefined,
          spotlights_heading: entry.meta.spotlights_heading,
          spotlights: spotlights.map((e: any) => {
            return {
              title:
                e.blog?.meta?.en?.title ||
                e.service?.meta?.en?.title ||
                e.industry?.meta?.en?.title ||
                '',
              slug:
                (e.blog?.meta?.en?.slug
                  ? `/our-insights/blog/${e.blog.meta.en.slug}`
                  : '') ||
                (e?.service?.meta?.en?.page_group?.selected
                  ? `${e.service.meta.en.page_group.selected?.toLowerCase()}/${
                      e.service.meta.en.slug
                    }`
                  : '') ||
                (e.industry?.meta?.en?.slug
                  ? `/industries/${e.industry.meta.en.slug}`
                  : '') ||
                '/',
              description:
                e.blog?.meta?.en?.description ||
                e.service?.meta?.en?.description ||
                e.industry?.meta?.en?.hero?.description ||
                '',
              image:
                e.blog?.meta?.en?.cover_image ||
                e.service?.meta?.en?.cover_image?.uri ||
                e.industry?.meta?.cover_image?.uri ||
                e.industry?.meta?.en?.hero?.image?.uri ||
                '',
              icon: e.icon,
            };
          }),
          link_groups: entry.meta.link_groups,
          map: entry.meta.map,
        },
      };
      const context: HomeViewContext = {
        page: JSON.stringify(props.page),
      };
      createPage({
        path: '/',
        component,
        context,
      });
    });
  },
);
