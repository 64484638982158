import { BCMSParserFunction } from '../parser';
import {
  OurInsightsOverviewViewProps,
  OurInsightsOverviewViewContext,
} from '../..';

export const OurInsightsOverviewParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const props: OurInsightsOverviewViewProps = {
      whitepapers: cache.whitepapers.slice(0, 3).map((e) => {
        return {
          title: e.meta.title,
          description: e.meta.description,
          cover_image: e.meta.cover_image,
          cover_image_alt_text: e.meta.cover_image_alt_text,
          slug: e.meta.slug,
        };
      }),
      blogs: cache.blogs.slice(0, 3).map((e) => {
        return {
          title: e.meta.title,
          description: e.meta.description,
          cover_image: e.meta.cover_image,
          cover_image_alt_text: e.meta.cover_image_alt_text,
          slug: e.meta.slug,
        };
      }),
    };
    const context: OurInsightsOverviewViewContext = {
      whitepapers: JSON.stringify(props.whitepapers),
      blogs: JSON.stringify(props.blogs),
    };
    createPage({
      path: '/our-insights',
      component,
      context,
    });
  },
);
