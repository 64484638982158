import React from 'react';
import { Article, Layout } from '../../components';
import { LegalPostViewProps } from '../../types';

const LegalPostView: React.FunctionComponent<LegalPostViewProps> = ({
  page,
  content,
}) => {
  return (
    <Layout
      title={page.title}
      description={page.description}
      OGImageUri={page.cover_image}
      className="articleWithPatternLayout"
      banner={{
        src: page.cover_image,
        srcAlt: page.cover_image_alt_text,
        title: page.title,
        subtitle: page.subtitle,
      }}
    >
      <div className="overview legal">
        <div className="wrapper">
          <Article content={content} />
        </div>
      </div>
    </Layout>
  );
};

export default LegalPostView;
