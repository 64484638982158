import {
  ImageGroup,
  IndustriesListViewContext,
  IndustriesListViewProps,
} from '../..';
import { BCMSParserFunction } from '../parser';

export const IndustriesListParser = BCMSParserFunction(
  async (createPage, component, cache) => {
    const list = cache.industries
      .map((e) => {
        return {
          cover_image: e.meta.cover_image as ImageGroup,
          overview_text: e.meta.hero?.description || '',
          slug: e.meta.slug,
          title: e.meta.title,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));

    const props: IndustriesListViewProps = {
      list,
    };

    const context: IndustriesListViewContext = {
      list: JSON.stringify(props.list),
    };

    createPage({
      path: `/industries`,
      component,
      context,
    });
  },
);
